// src/components/WashCostTable.js
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
const WashCostTable = ({ washCost, onEdit, onDelete }) => {

  return (
    <table className="min-w-full bg-white divide-y divide-gray-200 table-fixed w-full">
    <thead className="bg-gray-50">
      <tr>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Service</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase max-w-xs hidden lg:table-cell">Vehicle Type</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase max-w-xs hidden lg:table-cell">Branch</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Cost</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {washCost?.length > 0 ?  washCost.map((wash) => (         
        <tr key={wash.wash_cost_id}>
          <td className="px-4 py-2 whitespace-normal">
            <div className="flex items-center">
              <div>{wash?.serviceType?.name_en ? wash?.serviceType?.name_en : "ALL SERVICES"}</div>
            </div>
            <dl className="block lg:hidden  mt-2 text-gray-500 text-xs">
              <dd>{wash?.vehicleType?.name_en ? wash?.vehicleType?.name_en: "ALL TYPES"}</dd>         
            </dl>
          </td>
          <td className="px-4 py-2 whitespace-nowrap  overflow-hidden text-ellipsis max-w-xs hidden lg:table-cell">
          {wash?.vehicleType?.name_en ? wash?.vehicleType?.name_en: "ALL TYPES"}
          </td> 
          <td className="px-4 py-2 whitespace-nowrap  overflow-hidden text-ellipsis max-w-xs hidden lg:table-cell">
            {wash?.branch?.name ? wash?.branch?.name:"ALL BRANCHES" }
          </td> 
          <td className="px-4 py-2 whitespace-nowrap  overflow-hidden text-ellipsis max-w-xs ">
            {wash.cost}
          </td> 
          <td className="px-4 py-2 whitespace-nowrap flex space-x-2">
          <button className="text-blue-500" onClick={() => onEdit(wash)}>
                <FaEdit />
              </button>
              <button className="text-red-500" onClick={() => onDelete(wash)}>
                <FaTrash />
              </button>
          </td>
        </tr>
        ))
       : (
        <tr>
          <td colSpan="3" className="text-center py-4">
            No Wash Cost available.
          </td>
        </tr>
      )}
    </tbody>
  </table>
  );
};

export default WashCostTable;

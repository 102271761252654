import React, { useCallback, useEffect, useState } from 'react';
import { fetchWashCosts } from '../../api/washCostApi';
import { BiSolidErrorCircle } from 'react-icons/bi';
import BranchSelector from '../Dropdown/Branch';

const ServiceCheckList =({ onSelectionChange, vehicleType, onResetTotals}) => {
    const [washCosts, setWashCosts] = useState([]);
    const [branch_id, setBranchID] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchServices = async (filter={}) => {
        try {
          setIsLoading(true);
          // Simulate API call
          const data = await fetchWashCosts(filter);
          setWashCosts(data);
        } catch (err) {
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      };
    // Fetch washCosts on component mount
    useEffect(() => {  
  
      fetchServices();
    }, []);

    //if I enable the fetchServices it is cause endless rerender and api calls, All what I want to acheive it when I switch branch it should call the fetch with filter based on branch id
    const handleBranchChange = useCallback((branch) => { 
      const _branch_id=branch?.branch_id    
      console.log("handle Branch Change", _branch_id) 
               setBranchID((prev)=>  _branch_id);
               onResetTotals();
               setSelectedServices([]);
      },[onResetTotals,selectedServices]);
      
      useEffect(() => {  
        console.log("Vehicle Type", vehicleType);
        fetchServices({branch_id: branch_id,vehicle_type_id: vehicleType });
      }, [branch_id,vehicleType]); 
  
    const toggleServiceSelection = (wash) => {
      setSelectedServices((current) => {
        const newSelection = current.some((s) => s.wash_cost_id === wash.wash_cost_id)
          ? current.filter((s) => s.wash_cost_id !== wash.wash_cost_id)
          : [...current, wash];
        
        // Notify parent of the selection change
        onSelectionChange({
          type: 'washCosts',
          selected: newSelection,
          total: newSelection.reduce((sum, s) => sum + s?.cost, 0)
        });
  
        return newSelection;
      });
    };
  
    if (isLoading) {
      return (
        <div className="p-4 space-y-2">
          <div className="h-6 bg-gray-200 rounded animate-pulse w-1/3"></div>
          <div className="space-y-2">
            {[1, 2, 3].map((n) => (
              <div key={n} className="h-14 bg-gray-100 rounded animate-pulse"></div>
            ))}
          </div>
        </div>
      );
    }
  
    if (error) {
      return (
        <div className="p-4 text-red-600">
       <BiSolidErrorCircle /> Loading Wash Cost
        </div>
      );
    }
    
 
    return (<div className="p-4">
        <BranchSelector onBranchChange={handleBranchChange} />
        <h3 className="text-lg font-semibold mb-4 text-orange-500">
            Available Services
        </h3>
        <div className="space-y-3">
     
            {  !washCosts || washCosts?.length===0 ?     
             (
              <div className="p-4 text-red-600">
             <BiSolidErrorCircle /> NO Services Defined for this Branch
              </div>
            ) : washCosts.map((wash) => (
                <div
                    key={wash.wash_cost_id}
                    onClick={() => toggleServiceSelection(wash)}
                    className={`flex justify-between items-center rounded-3xl border-b p-2 cursor-pointer transition-all duration-200 
                ${selectedServices.some((s) => s.wash_cost_id === wash.wash_cost_id)
                            ? "bg-blue-100 border-blue-300"
                            : "hover:bg-gray-100"
                        }`}
                >
                    <div className="flex items-center ">
                        <input
                            type="checkbox"
                            checked={selectedServices.some(
                                (s) => s.wash_cost_id === wash.wash_cost_id
                            )}
                            onChange={() => { }} // Prevents warning
                            className="mr-2"
                        />
                        <span>{wash?.serviceType?.name_en ? wash?.serviceType?.name_en : "ALL SERVICES"}</span>
                    </div>
                    <span className="font-semibold">
                        {wash.cost} QAR
                    </span>
                </div>
            ))}
        </div>
    </div>);

};
export default ServiceCheckList;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { createCoupon, editCoupon } from "../../../api/couponApi";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateCouponForm = ({ couponData, onSubmit, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (couponData) {
      setValue("name", couponData.name);
      setValue("discount_value", couponData.discount_value);
      setValue("description", couponData.description);
      const formattedDate = new Date(couponData.valid_until).toISOString().split("T")[0];
      setValue("valid_until", formattedDate);
      setValue("max_users", couponData.max_users);
      setValue("discount_type", couponData.discount_type);
    } else {
      reset();
    }
  }, [couponData, setValue, reset]);

  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      if (couponData?.coupon_id) {
        const processedData = {
          ...data,
          coupon_id: couponData?.coupon_id,
      };
        await editCoupon(processedData, data);
        toast.success("Coupon updated successfully!", {
          position: "top-center",
          autoClose: 3000,
          transition: Bounce,
        });
      } else {
        await createCoupon(data);
        toast.success("Coupon created successfully!", {
          position: "top-center",
          autoClose: 3000,
          transition: Bounce,
        });
      }
      onSubmit(); // Refetch data in parent component
    } catch (error) {
      console.error("Failed to save coupon:", error);
      toast.error(`Error: ${error.message}`, {
        position: "top-center",
        autoClose: 3000,
        transition: Bounce,
      });
    } finally {
      setIsSubmitting(false);
      reset();
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg px-6">
      <h2 className="text-2xl font-bold text-center mb-6">
        {couponData ? "Edit Coupon" : "Create Coupon"}
      </h2>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {/* Coupon Code */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Code</label>
          <input
            type="text"
            {...register("name", { required: "Coupon code is required" })}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
          {errors.name && (
            <p className="text-red-500 text-sm">{errors.name.message}</p>
          )}
        </div>

        {/* Discount */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Discount (%)
          </label>
          <input
            type="number"
            {...register("discount_value", {
              required: "Discount is required",
              min: { value: 0, message: "Discount cannot be negative" },
            })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.discount_value && (
            <p className="text-red-500 text-sm">{errors.discount_value.message}</p>
          )}
        </div>

        {/* Description*/}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
          Description
          </label>
          <input
            type="text"
            {...register("description", { required: "description  is required" })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.description && (
            <p className="text-red-500 text-sm">{errors.description.message}</p>
          )}
        </div>

        {/* Validity Date */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Validity Date</label>
          <input
            type="date"
            {...register("valid_until", { required: "Validity date is required" })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.valid_until && (
            <p className="text-red-500 text-sm">{errors.valid_until.message}</p>
          )}
        </div>

        {/* Usage Limit */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Usage Limit
          </label>
          <input
            type="number"
            {...register("max_users", {
              required: "Usage limit is required",
              min: { value: 1, message: "Must be at least 1" },
            })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.max_users && (
            <p className="text-red-500 text-sm">{errors.max_users.message}</p>
          )}
        </div>

        {/* Discount Type */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Discount Type
          </label>
          <select
            {...register("discount_type", { required: "Discount type is required" })}
            className="w-full px-3 py-2 border rounded-full"
          >
            <option value="">Select Type</option>
            <option value="percentage">Percentage</option>
            <option value="amount">Fixed Amount</option>
          </select>
          {errors.discount_type && (
            <p className="text-red-500 text-sm">{errors.discount_type.message}</p>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={()=> {reset(); onClose(); }}
            className="px-4 py-2 bg-gray-800 text-white rounded-full"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`px-4 py-2 rounded-full ${
              isSubmitting ? "bg-gray-300" : "bg-[#FF8C00]"
            } text-white`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCouponForm;

import React, { useState, useEffect } from "react";
import config from '../config';

const BannerImage = () => {
  const [imageExists, setImageExists] = useState(false);
  const imageUrl = `${config.apiUrl}/assets/uploads/banner.png`;

  useEffect(() => {
    // Check if the image exists
    const checkImage = async () => {
      try {
        const response = await fetch(imageUrl, { method: "HEAD" }); // HEAD request to check file existence
        if (response.ok) {
          setImageExists(true);
        } else {
          setImageExists(false);
        }
      } catch (error) {
        setImageExists(false);
      }
    };

    checkImage();
  }, [imageUrl]);

  return (
    <div className="flex w-full justify-center">
      {imageExists ? (
        <img src={imageUrl} alt="Banner" className="banner-image" />
      ) : (
        <p className='text-center flex justify-center'>No banner yet</p>
      )}
    </div>
  );
};

export default BannerImage;

import { useEffect, useState } from "react";
import { fetchSubscriptions } from "../../api/subscriptionApi";

const SubscriptionList=({ChooseSubsciption})=>{
    const [subscriptions, setSubscriptiones] = useState([]);
    useEffect(() => {
        async function getsubscriptions() {
          const data = await fetchSubscriptions({}); // Fetch subscription list
          setSubscriptiones(data);
        }
        getsubscriptions();
      }, []);
const handlehooseSubsciption=(data)=>{
    ChooseSubsciption(data)
};
    return(
        <div className=" flex flex-wrap gap-2 shadow-md p-2 rounded-md justify-center items-center overflow-scroll ">
        {subscriptions?.map((subscriptionData, index) => (
          <button
            key={subscriptionData.subscription_id}
            type="button"
            onClick={()=> handlehooseSubsciption(subscriptionData)}
            style={{ backgroundColor: subscriptionData.color || "#f0f0f0" }}
            className="p-2 text-white rounded-md items-start"
          >
            {subscriptionData.name_en}
            <div className="text-white text-sm px-4 right-4 font-extrabold flex items-center justify-center gap-2 ">
              Cars :  {subscriptionData.number_of_cars}
            </div>
            <div className="text-white text-sm px-4">
              <span>{subscriptionData.duration_days} days</span>
            </div>
            <div className=" bg-gradient-to-r from-[#F9EED9] to-[#DEB686]  rounded-full p-2 bottom-4 right-4 text-stone-800 text font-extrabold">
              <span> {subscriptionData.cost} QAR</span>

            </div>
            <span>One Year : {subscriptionData.one_year_offer} QAR</span>
          </button>
        ))}
      </div>
    );
}

export default SubscriptionList;
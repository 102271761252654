const AddVehicleToSub =({subscriptionWithRoom,onAddVehicletoPlan})=>{

    return (
        <div className="flex flex-col justify-between w-full bg-white rounded-lg shadow-md p-4 mb-2">
          <div className="flex flex-row justify-between items-center">
            <span>Subscription Available</span>
            <span className="text-green-600 font-semibold text-xl">
              {subscriptionWithRoom.subscription_name}
            </span>
          </div>
          <div>
            Available Slots:{' '}
            {JSON.parse(subscriptionWithRoom.subscription_features).number_of_cars -
              subscriptionWithRoom.subscribedVehicles.length}
          </div>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded mt-2"
            onClick={onAddVehicletoPlan}
          >
            Add This Vehicle
          </button>
        </div>
      );
}
export default AddVehicleToSub;
// src/components/CreateServiceType.js
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
const CreateServiceType = ({ onClose, onSubmit, serviceData }) => {
  const { register, handleSubmit, reset, setValue,  } = useForm();

  useEffect(() => {
    if (serviceData) {
      // Fill form with service data for editing
      setValue("name_en", serviceData.name_en);
      setValue("name_ar", serviceData.name_ar);
    } else {
      reset(); // Reset form if no serviceData
    }

  }, [serviceData, setValue, reset]);

  
  const handleFormSubmit = (data) => {
    onSubmit({ ...data, service_type_id: serviceData?.service_type_id, });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">{serviceData ? "Edit service Type" : "Create New service Type"}</h2>

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <input
            {...register('name_en', { required: true })}
            placeholder="Name"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register('name_ar', { required: true })}
            placeholder="Arabic Name"
            className="w-full p-2 border rounded-full mb-3"
          />          
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="mr-2 p-2 bg-gray-800 text-white rounded-full">
              Cancel
            </button>
            <button type="submit" className="p-2 bg-[#FF8C00] text-white rounded-full">
              {serviceData ? "Save Changes" : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateServiceType;

import React, { useState, useRef, useEffect, useCallback } from "react";
import { FaCodeBranch, FaChevronDown } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { fetchBranches } from "../../api/branchApi";

const STORAGE_KEY = 'selectedBranch';

const BranchSelector = ({ onBranchChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  const { adminBranch, setAdminSelectedBranch } = useAuth();

  // Load branches and initialize selected branch
  useEffect(() => {
    const loadBranches = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        // Fetch branches
        const fetchedBranches = await fetchBranches({ name: "" });
        setBranches((prev)=>fetchedBranches);

        // Get selected branch from localStorage
        const storedBranch = localStorage.getItem(STORAGE_KEY);
        if (storedBranch) {
          const parsedBranch = JSON.parse(storedBranch);
          // Find the matching branch from fetched branches to ensure we have fresh data
          const matchingBranch = fetchedBranches.find(
            branch => branch.branch_id === parsedBranch.branch_id
          );
          if (matchingBranch) {
            setSelectedBranch((prev)=>matchingBranch);
            setAdminSelectedBranch((prev)=>matchingBranch);
            onBranchChange?.(matchingBranch);
          }
        }
      } catch (err) {
        setError('Failed to load branches');
        console.error('Error loading branches:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadBranches();
  }, [setAdminSelectedBranch]);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleBranchSelect = useCallback((branch) => {
    setSelectedBranch(branch);
    setAdminSelectedBranch(branch);
    setIsOpen(false);

    // Save to localStorage (null for "All Branches")
    if (branch) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(branch));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }

    // Notify parent component
    onBranchChange?.(branch);
  },[onBranchChange, selectedBranch, setAdminSelectedBranch]);

  // If admin has assigned branch, show static display
  if (adminBranch) {
    return (
      <div className="flex items-center px-4 py-2 text-sm text-gray-700 bg-gray-50 rounded-lg">
        <FaCodeBranch className="mr-3 w-4 h-4 text-gray-500" />
        <span className="font-medium">{adminBranch.name}</span>
      </div>
    );
  }

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Selector Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        disabled={isLoading}
        className={`flex items-center justify-between w-56 px-4 py-2 text-sm 
          bg-white rounded-lg border shadow-sm hover:bg-gray-50 
          transition-colors duration-150 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <div className="flex items-center">
          <FaCodeBranch className="mr-3 w-4 h-4 text-gray-500" />
          <span className="text-gray-700">
            {isLoading ? 'Loading...' : 
             selectedBranch ? selectedBranch.name : 'All Branches'}
          </span>
        </div>
        <FaChevronDown
          className={`w-4 h-4 text-gray-500 transition-transform duration-150
            ${isOpen ? 'transform rotate-180' : ''}`}
        />
      </button>

      {/* Dropdown Menu */}
      {isOpen && !isLoading && (
        <div className="absolute right-0 mt-2 w-56 max-h-60 overflow-y-auto
          bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-50">
          {error ? (
            <div className="px-4 py-2 text-sm text-red-600">
              {error}
            </div>
          ) : (
            <div className="py-1">
              {/* Selected Branch (if any) */}
              {selectedBranch && (
                <button
                  onClick={() => handleBranchSelect(selectedBranch)}
                  className="flex items-center w-full px-4 py-2 text-sm text-left
                    bg-blue-50 text-blue-700"
                >
                  {selectedBranch.name}
                </button>
              )}
              
              {/* All Branches Option */}
              <button
                onClick={() => handleBranchSelect(null)}
                className={`flex items-center w-full px-4 py-2 text-sm text-left
                  ${!selectedBranch ? 'bg-blue-50 text-blue-700' : 'text-gray-700 hover:bg-gray-50'}`}
              >
                All Branches
              </button>
              
              {/* Other Branch Options */}
              {branches
                .filter(branch => !selectedBranch || branch.branch_id !== selectedBranch.branch_id)
                .map((branch) => (
                  <button
                    key={branch.branch_id}
                    onClick={() => handleBranchSelect(branch)}
                    className="flex items-center w-full px-4 py-2 text-sm text-left
                      text-gray-700 hover:bg-gray-50"
                  >
                    {branch.name}
                  </button>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BranchSelector;
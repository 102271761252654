// src/components/CreateVehicleType.js
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
const CreateVehicleType = ({ onClose, onSubmit, vehicleData }) => {
  const { register, handleSubmit, reset, setValue,  } = useForm();

  useEffect(() => {
    if (vehicleData) {
      // Fill form with vehicle data for editing
      setValue("name_en", vehicleData.name_en);
      setValue("name_ar", vehicleData.name_ar);
    } else {
      reset(); // Reset form if no vehicleData
    }

  }, [vehicleData, setValue, reset]);

  
  const handleFormSubmit = (data) => {
    onSubmit({ ...data, vehicle_type_id: vehicleData?.vehicle_type_id, });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">{vehicleData ? "Edit vehicle Type" : "Create New vehicle Type"}</h2>

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <input
            {...register('name_en', { required: true })}
            placeholder="Name"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register('name_ar', { required: true })}
            placeholder="Arabic Name"
            className="w-full p-2 border rounded-full mb-3"
          />          
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="mr-2 p-2 bg-gray-800 text-white rounded-full">
              Cancel
            </button>
            <button type="submit" className="p-2 bg-[#FF8C00] text-white rounded-full">
              {vehicleData ? "Save Changes" : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateVehicleType;

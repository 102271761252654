// src/components/AdminTable.js
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const AdminTable = ({ admins, searchTerm,onEdit, onDelete }) => {
  const filteredAdmins = admins.filter((admin) =>
    admin.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <table className="min-w-full bg-white divide-y divide-gray-200 table-fixed w-full">
    <thead className="bg-gray-50">
      <tr>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Username</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase hidden lg:table-cell">Email</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase hidden lg:table-cell">Role</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase hidden lg:table-cell">Branch</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {filteredAdmins.map((admin) => (
        <tr key={admin.admin_id}>
          {/* Username */}
          <td className="px-4 py-2 whitespace-normal">
            <div className="flex items-center">
              <div>{admin.username}</div>
            </div>
            {/* Extra info for mobile view */}
            <dl className="block lg:hidden  mt-2 text-gray-500 text-xs">
              <dd>Role: {admin.role_type}</dd>
              <dd>email: {admin.email}</dd>
              <dd>Branch: {admin.branch ? admin.branch.name : "ALL"}</dd>
            </dl>
          </td>

          {/* Email */}
          <td className="px-4 py-2 whitespace-nowrap  overflow-hidden text-ellipsis max-w-xs hidden lg:table-cell">
            {admin.email}
          </td>

          {/* Role */}
          <td className="px-4 py-2 whitespace-nowrap hidden lg:table-cell" >
            {admin.role_type}
          </td>

          {/* Branch (hidden on small screens) */}
          <td className="px-4 py-2 whitespace-nowrap hidden lg:table-cell">
            {admin.branch ? admin.branch.name : "ALL"}
          </td>

          {/* Actions */}
          <td className="px-4 py-2 whitespace-nowrap flex space-x-2">
          <button className="text-blue-500" onClick={() => onEdit(admin)}>
                <FaEdit />
              </button>
              <button className="text-red-500" onClick={() => onDelete(admin)}>
                <FaTrash />
              </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  );
};

export default AdminTable;

// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [adminRole, setAdminRole] = useState(null); // Role of the admin
  const [adminData, setAdminData] = useState(null); // Optional: Store admin details
  const [adminBranch, setAdminBranch] = useState(null); // Optional: Store admin details
  const [adminSelectedBranch, setAdminSelectedBranch] = useState(null); // Optional: Store admin details

    // Check if the user is logged in on initial load
    useEffect(() => {
        const storedAdminData = JSON.parse(localStorage.getItem('admin'));
        if (storedAdminData) {
          setIsAuthenticated(true);
          setAdminData(storedAdminData);
          setAdminRole(storedAdminData.role_type); // Set the role type
          setAdminBranch(storedAdminData?.branch); // Optionally, store the entire admin object for later use
          setAdminSelectedBranch(storedAdminData?.branch); // Optionally, store the entire admin object for later use
        console.log(storedAdminData?.branch);
        }
      }, []);
 
    
      // Clear authentication state and remove from localStorage
      const logout = () => {
        setIsAuthenticated(false);
        setAdminData(null);
        setAdminRole('');
        setAdminBranch('');
        setAdminSelectedBranch('');
        localStorage.removeItem('admin'); // Remove admin data from localStorage
      };
  // Define the authenticate function (login)
  const authenticate = (admin) => {
    if (admin && admin.role_type) {
        setIsAuthenticated(true); 
        setAdminRole(admin.role_type); // Set the admin role (SuperAdmin, Supervisor, Cashier)
        setAdminData(admin); // Optionally, store the entire admin object for later use
        setAdminBranch(admin?.branch); // Optionally, store the entire admin object for later use
        console.log(admin?.branch);

        localStorage.setItem('admin', JSON.stringify(admin)); // Store admin data in localStorage
        return true;
      }
      return false;
  };

  

  return (
    <AuthContext.Provider value={{ isAuthenticated,adminData,adminRole,authenticate,logout,adminBranch,setAdminBranch,adminSelectedBranch, setAdminSelectedBranch,setAdminData }}>
      {children}
    </AuthContext.Provider>
  );
};

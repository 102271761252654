// src/components/Dashboard.js
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CarDetails from "../components/CarDetails";
import {
  FaCheck,
  FaCheckDouble,
  FaCreditCard,
  FaExclamationCircle,
} from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import TodaySummery from "../components/POS/TodaySummery";
import ServiceCheckList from "../components/POS/ServiceCheckList";
import SlotList from "../components/POS/SlotList";
import LookUpClient from "../components/POS/LookUpClient";
import LookUpVehicle from "../components/POS/LookUpVehicle";
import SubscriptionManager from "../components/POS/SubscriptionManager";
import CurrentSubscription from "./CurrentSubscription";
import AddVehicleToSub from "../components/POS/AddVehicleToSub";
import {
  updateClientSubscription,
  AddVehicleToSSubscription,
} from "../api/subscriptionApi";
import { useAuth } from "../context/AuthContext";
import { createSales, getSalesById } from "../api/salesApi";
import PrintSalesReceipt from "../components/POS/PrintSalesReceipt";
import { IoIosPrint, IoIosRefresh } from "react-icons/io";


const POS = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const { adminSelectedBranch, adminData } = useAuth();

  const [isProcessing, setIsProcessing] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectVehicle, setSelectedVehicle] = useState(null);
  const [matchingSubscription, setMatchingSubscription] = useState(null);
  const [subscriptionWithRoom, setSubscriptionWithRoom] = useState(null);
  const [isFree, setIsFree] = useState(false);
  const [showPrintMadal, setShowrintModal] = useState(false);
  const [totals, setTotals] = useState({
    services: { selected: [], total: 0 },
    products: { selected: [], total: 0 },
    addons: { selected: [], total: 0 },
    washCosts: { selected: [], total: 0 },
  });
  const [salesReceipt, setSalesReceipt] = useState(null);

  const handleClientSelected = (client) => {
    setSelectedClient(client);
  };

  //here where I am setting my Vehicle and Client where if includes the clientSubscriptions
  const handleVehicleSelected = (vehicle) => {
    setSelectedVehicle((prev) => (prev = vehicle));
    setSelectedClient((prev) => (prev = vehicle?.client));
  };
  const checkSubscriptionStatus = useCallback((endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    const timeDifference = end - today;
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const isExpired = daysRemaining < 0;
    return {
      isExpired,
      daysRemaining: isExpired ? 0 : daysRemaining,
    };
  }, []);

  useEffect(() => {
    if (!selectedClient || !selectVehicle) {
      setMatchingSubscription(null);
      setSubscriptionWithRoom(null);
      return;
    }

    const clientSubscriptions = selectedClient?.clientSubscriptions || [];

    // Find matching subscription
    const foundMatchingSubscription = clientSubscriptions.find((subscription) =>
      subscription.subscribedVehicles.some(
        (vehicle) => vehicle.vehicle.vehicle_id === selectVehicle.vehicle_id
      )
    );

    if (foundMatchingSubscription) {
      setMatchingSubscription(foundMatchingSubscription);
      const isExpired = checkSubscriptionStatus(
        foundMatchingSubscription.end_date
      ).isExpired;
      const paymentStatus = foundMatchingSubscription.payment_status;

      setIsFree(
        selectedClient.free_wash_status ||
          (!isExpired && paymentStatus !== "Pending")
      );
    } else {
      setMatchingSubscription(null);

      // Check for subscriptions with room for new vehicles
      const foundSubscriptionWithRoom = clientSubscriptions.find(
        (subscription) => {
          const features = JSON.parse(
            subscription.subscription_features || "{}"
          );
          const maxVehicles = features.number_of_cars || 0;
          const subscribedVehiclesCount =
            subscription.subscribedVehicles.length;
          return subscribedVehiclesCount < maxVehicles;
        }
      );
      setSubscriptionWithRoom(foundSubscriptionWithRoom);
      setIsFree(selectedClient.free_wash_status);
    }

    console.log(selectedClient.free_wash_status);
  }, [selectVehicle, selectedClient, checkSubscriptionStatus]);

  const handClientRest = (client) => {
    setSelectedClient(null);
  };
  const handVehicleRest = (client) => {
    setSelectedVehicle(null);
  };
  const handleSlotChange = (service) => {
    console.log(service);
  };
  const calculateEndDate = (startDate, durationDays) => {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + durationDays);
    return endDate;
  };
  //add to Sales Logs under Renew
  const handleSubRenew = async (payment_type) => {
    // Calculate duration and dates
    const durationDays = Math.ceil(
      (new Date(matchingSubscription.end_date) -
        new Date(matchingSubscription.start_date)) /
        (1000 * 60 * 60 * 24)
    );
    const startDate = new Date();
    const endDate = calculateEndDate(startDate, durationDays);

    // Prepare payload for subscription update
    const subscriptionPayload = {
      admin_id: adminData.admin_id,
      client_subscription_id: matchingSubscription.client_subscription_id,
      client_id: selectedClient.client_id,
      vehicle_id: selectVehicle.vehicle_id,
      subscription_id: matchingSubscription.subscription_id,
      subscription_cost: matchingSubscription.subscription_cost,
      start_date: startDate,
      end_date: endDate,
      payment_status: "Paid",
    };

    // Prepare sales data payload
    const salesData = {
      admin_id: adminData.admin_id,
      branch_id: adminSelectedBranch.branch_id,
      client_subscription_id: matchingSubscription.client_subscription_id,
      client_id: selectedClient.client_id,
      vehicle_id: selectVehicle.vehicle_id,
      price: matchingSubscription.subscription_cost,
      payment_type: payment_type,
      action: "RENEW",
      coupon_id: null,
      discount: 0,
      total: matchingSubscription.subscription_cost,
    };

    try {
      setIsProcessing(true);

      // Update subscription to Paid
      await updateClientSubscription(subscriptionPayload);
      console.log("Subscription status updated to Paid");

      // Create sales record
      const newSales = await createSales(salesData);
      const salesDetails = await getSalesById(newSales.sales_id);

      toast.success(`Subscription renewal successfully!`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSalesReceipt(salesDetails);
      // try {
      //   const newNotification = {
      //     client_id: selectedClient.client_id, // Replace with the actual client ID
      //     title: "Payment Receied!",
      //     body: `An amount of ${matchingSubscription.subscription_cost} QAR by ${payment_type} for subscription renewal at ${adminSelectedBranch.name}`,
      //     type: "payment",
      //   };
      //   const createdNotification = await createNotification(newNotification);
      //   console.log("Created Notification:", createdNotification);
      // } catch (error) {
      //   console.error("Error creating notification:", error.message);
      // }
    } catch (error) {
      toast.error(`Error occurred during renewal payment settlement!`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      // Roll back subscription payment status to Pending if sales creation failed
      if (error.message.includes("Sales")) {
        try {
          subscriptionPayload.payment_status = "Pending";
          await updateClientSubscription(subscriptionPayload);
          console.log("Subscription status rolled back to Pending");
        } catch (rollbackError) {
          console.error(
            "Failed to roll back subscription status:",
            rollbackError
          );
        }
      }
    } finally {
      setIsProcessing(false);
      setRefreshKey((prev) => prev + 1);
    }
  };
  //Add Sales Logs to NEW
  const handleSettlePayment = async (payment_type) => {
    console.log(payment_type);

    // Calculate duration and dates
    const durationDays = Math.ceil(
      (new Date(matchingSubscription.end_date) -
        new Date(matchingSubscription.start_date)) /
        (1000 * 60 * 60 * 24)
    );
    const startDate = new Date();
    const endDate = calculateEndDate(startDate, durationDays);

    // Prepare payload for subscription update
    const subscriptionPayload = {
      admin_id: adminData.admin_id,
      branch_id: adminSelectedBranch.branch_id,
      client_subscription_id: matchingSubscription.client_subscription_id,
      client_id: selectedClient.client_id,
      vehicle_id: selectVehicle.vehicle_id,
      subscription_id: matchingSubscription.subscription_id,
      subscription_cost: matchingSubscription.subscription_cost,
      start_date: startDate,
      end_date: endDate,
      payment_status: "Paid",
    };

    // Prepare sales data payload
    const salesData = {
      admin_id: adminData.admin_id,
      branch_id: adminSelectedBranch.branch_id,
      client_subscription_id: matchingSubscription.client_subscription_id,
      client_id: selectedClient.client_id,
      vehicle_id: selectVehicle.vehicle_id,
      price: matchingSubscription.subscription_cost,
      payment_type: payment_type,
      action: "SUBSCRIBE",
      coupon_id: null,
      discount: 0,
      total: matchingSubscription.subscription_cost,
    };

    try {
      setIsProcessing(true);

      // Update subscription to Paid
      await updateClientSubscription(subscriptionPayload);
      console.log("Subscription status updated to Paid");

      // Create sales record
      const newSales = await createSales(salesData);
      console.log("Sales record created:", newSales);
      const salesDetails = await getSalesById(newSales.sales_id);

      toast.success(`Subscription purchased successfully!`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSalesReceipt(salesDetails);
     
    } catch (error) {
      console.error("Error occurred during payment settlement:", error);

      // Roll back subscription payment status to Pending if sales creation failed
      if (error.message.includes("Sales")) {
        try {
          subscriptionPayload.payment_status = "Pending";
          await updateClientSubscription(subscriptionPayload);
          console.log("Subscription status rolled back to Pending");
        } catch (rollbackError) {
          console.error(
            "Failed to roll back subscription status:",
            rollbackError
          );
        }
      }
      toast.success(`Error purchasing subscription!`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setIsProcessing(false);
      setRefreshKey((prev) => prev + 1);
    }
  };
  const handleAddVehicleToPlan = async () => {
    console.log(subscriptionWithRoom);
    const payload = {
      client_subscription_id: subscriptionWithRoom.client_subscription_id,
      client_id: selectedClient.client_id,
      vehicle_id: selectVehicle.vehicle_id,
      subscription_id: subscriptionWithRoom.subscription_id,
    };
    try {
      setIsProcessing(true);
      await AddVehicleToSSubscription(payload); // Call API to  subscription
      toast.success(`Vehicle Added successfully!`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      toast.error(`Error Adding Vehicle to subscription: ${error.message} `, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setIsProcessing(false);
      setRefreshKey((prev) => prev + 1);
    }
  };

  const handlePurchaseSuccess = (subscription) => {
    setSelectedClient(null);
    setSelectedVehicle(null);
    setMatchingSubscription(null);
    setIsFree(false);
    setRefreshKey((prev) => prev + 1);
    // console.log("CURRENT SUBSCRIPTION", subscription)
  };

  const handleSelectionChange = (update) => {
    setTotals((current) => ({
      ...current,
      [update.type]: {
        selected: update.selected,
        total: update.total,
      },
    }));
  };
  const resetTotals = useCallback(() => {
    setTotals({
      services: { selected: [], total: 0 },
      products: { selected: [], total: 0 },
      addons: { selected: [], total: 0 },
      washCosts: { selected: [], total: 0 },
    });
    return Object.values(totals).reduce(
      (sum, category) => sum + category?.total,
      0
    );
  }, [totals]);

  // Memoized calculation of the grand total
  const grandTotal = useMemo(() => {
    return isFree
      ? 0
      : Object.values(totals).reduce(
          (sum, category) => sum + category.total,
          0
        );
  }, [totals, isFree]);

  //Should complete WASH PAYMENT and AND TO SALES AND SERVICE DETAILS
  const handleWashPayment = async (payment_type) => {
    console.log(payment_type);

    // Prepare sales data payload
    const salesData = {
      admin_id: adminData.admin_id,
      branch_id: adminSelectedBranch.branch_id,
      client_subscription_id: matchingSubscription?.client_subscription_id,
      client_id: selectedClient.client_id,
      vehicle_id: selectVehicle.vehicle_id,
      price: grandTotal,
      payment_type: payment_type,
      action: "WASH",
      coupon_id: null,
      discount: 0,
      total: grandTotal,
    };

    try {
      setIsProcessing(true);
      // Create sales record
      const newSales = await createSales(salesData);
      console.log("Sales record created:", newSales);
      const salesDetails = await getSalesById(newSales.sales_id);
      console.log(salesDetails);
      toast.success(`Sales Recorded! `, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSalesReceipt(salesDetails);
      // try {
      //   const newNotification = {
      //     client_id: selectedClient.client_id, // Replace with the actual client ID
      //     title: "Shine AnyTime!",
      //     body: `Washing done at ${adminSelectedBranch.name}`,
      //     type: "wash",
      //   };
      //   const createdNotification = await createNotification(newNotification);
      //   console.log("Created Notification:", createdNotification);
      // } catch (error) {
      //   console.error("Error creating notification:", error.message);
      // }
      // try {
      //   const newNotification = {
      //     client_id: selectedClient.client_id, // Replace with the actual client ID
      //     title: "Payment!",
      //     body: `Payment of ${grandTotal} QAR received at ${adminSelectedBranch.name} by ${payment_type}`,
      //     type: "payment",
      //   };
      //   const createdNotification = await createNotification(newNotification);
      //   console.log("Created Notification:", createdNotification);
      // } catch (error) {
      //   console.error("Error creating notification:", error.message);
      // }
    } catch (error) {
      toast.error(`Error occurred during payment settlement! `, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      console.error("Error occurred during payment settlement:", error);
    } finally {
      setIsProcessing(false);
      setRefreshKey((prev) => prev + 1);
    }
  };
  const handleRefresh = useCallback(() => {
    setSalesReceipt(null);
    resetTotals();
    setSelectedClient(null);
    setSelectedVehicle(null);
    setMatchingSubscription(null);
    setSubscriptionWithRoom(null);
    setIsFree(false);
    // setRefreshKey((prev) => prev + 1);
  }, [
    resetTotals,
    selectVehicle,
    selectedClient,
    matchingSubscription,
    subscriptionWithRoom,
  ]);
  return (
    <div className="relative flex flex-col lg:flex-row h-full antialiased bg-gray-100">
      {/* Main Content Area */}
      <div className="w-full lg:w-[70%] flex flex-col relative p-2">
        <TodaySummery refreshKey={refreshKey} startDate={null} endDate={null} />
        {/* Vehicle Section */}
        <div className="bg-white rounded-lg shadow-md p-2 mb-2">
          <h2 className="text-xl font-semibold mb-2">
            Vehicle Search / Registration
          </h2>
          {/* Plate Number Search */}
          <LookUpVehicle
            onResetVehicle={handVehicleRest}
            onVehicleSelected={handleVehicleSelected}
            clientData={selectedClient}
            onRefresh={refreshKey}
          />
          {selectVehicle && selectVehicle.length !== 0 && selectedClient && (
            <div className="w-full mt-4">
              <CarDetails
                plateNumber={selectVehicle?.plate_number}
                color={selectVehicle?.color}
                model={selectVehicle?.model}
                type={selectVehicle?.vehicletype?.name_en}
                registration={selectVehicle?.registration}
                brandLogo="https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/toyota.png"
              />
            </div>
          )}
        </div>
        {/* Client Section */}
        <div className="bg-white rounded-lg shadow-md p-2 mb-2">
          <h2 className="text-xl font-semibold mb-1">Client Information</h2>
          {(!selectVehicle || !selectedClient) && (
            <LookUpClient
              onClientSelected={handleClientSelected}
              onRestClient={handClientRest}
            />
          )}
          {selectedClient && (
            <div
              className={`mt-4 p-2 rounded-md shadow-md ${
                selectedClient.is_profile_complete
                  ? "bg-blue-200"
                  : "bg-gray-200"
              }`}
            >
              <p>
                <strong>Username:</strong> {selectedClient.username}
              </p>
              {selectedClient.mobile && (
                <p className="flex flex-row gap-2">
                  <strong>Mobile:</strong> {selectedClient.mobile}{" "}
                  {selectedClient.is_mobile_validated ? (
                    <FaCheckDouble className=" text-blue-600" />
                  ) : (
                    <FaCheck className=" text-orange-600" />
                  )}
                </p>
              )}
              {selectedClient.email && (
                <p className="flex flex-row gap-2">
                  <strong>Email:</strong> {selectedClient.email}{" "}
                  {selectedClient.is_email_validated ? (
                    <FaCheckDouble className=" text-blue-600" />
                  ) : (
                    <FaCheck className=" text-orange-600" />
                  )}
                </p>
              )}
            </div>
          )}
        </div>
        {selectedClient && selectVehicle ? (
          matchingSubscription ? (
            <CurrentSubscription
              subscription={matchingSubscription}
              onRenew={handleSubRenew} //my confusion
              onPay={handleSettlePayment} //{handleSettlePayment} //myconfusion
              checkSubscriptionStatus={checkSubscriptionStatus}
            />
          ) : subscriptionWithRoom ? (
            <AddVehicleToSub
              subscriptionWithRoom={subscriptionWithRoom}
              onAddVehicletoPlan={handleAddVehicleToPlan}
            />
          ) : (
            <div className="flex flex-col justify-between w-full bg-white rounded-lg shadow-md p-2 mb-2">
              <div>No subscriptions available for this vehicle.</div>
              <SubscriptionManager
                clientData={selectedClient}
                vehicleData={selectVehicle}
                returnCallback={handlePurchaseSuccess}
              />
            </div>
          )
        ) : (
          <div className="flex justify-start gap-2 items-center w-full bg-orange-300 rounded-lg shadow-md p-2 mb-2">
            <FaExclamationCircle /> Please select a client and vehicle to
            proceed.
          </div>
        )}
      </div>

      {/* Right Sidebar */}
      <div className="w-full lg:w-[30%] bg-white lg:bg-blue-gray-50 p-2 relative">
        <div className="bg-white rounded-3xl shadow-md flex flex-col h-full">
          {/* Services List */}
          {selectVehicle && selectVehicle.length !== 0 && selectedClient && (
            <ServiceCheckList
              vehicleType={selectVehicle?.vehicle_type_id}
              onSelectionChange={handleSelectionChange}
              onResetTotals={resetTotals}
            />
          )}
          {/* Slots List */}
          <SlotList onSlotChange={handleSlotChange} />
          {/* Total Section */}
          <div className="mt-auto p-2 border-t">
            <div className="flex justify-between mb-3">
              <span className="font-semibold">Total</span>
              <span className="font-bold text-lg">
                {selectedClient && isFree ? 0 : grandTotal} QAR
              </span>
            </div>
            {/* Bottom Buttons */}
            {salesReceipt ? (
              <div className="flex justify-between space-x-4">
                <button
                  onClick={() => {
                    handleRefresh();
                  }}
                  className="flex gap-2 w-full flex-row justify-center items-center px-2 font-bold text-lg bg-orange-400 text-white py-3 rounded-lg hover:bg-green-600"
                >
                  <IoIosRefresh /> CLEAR
                </button>
                <button
                  onClick={() => {
                    setShowrintModal(true);
                  }}
                  className="flex gap-2 w-full flex-row justify-center items-center px-2 font-bold text-lg bg-blue-400 text-white py-3 rounded-lg hover:bg-green-600"
                >
                  <IoIosPrint /> PRINT
                </button>
              </div>
            ) : (
              selectVehicle &&
              selectVehicle.length !== 0 &&
              totals?.washCosts?.selected.length > 0 && (
                <div className="flex justify-between space-x-4">
                  {selectedClient && isFree ? (
                    <button
                      onClick={() => {
                        handleWashPayment("FREE WASH");
                      }}
                      className="flex gap-2 w-full flex-row justify-center items-center px-2 font-bold text-lg bg-orange-400 text-white py-3 rounded-lg hover:bg-green-600"
                    >
                      <FaMoneyBillTransfer /> FREE
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          handleWashPayment("CASH");
                        }}
                        className="flex gap-2 flex-row justify-center items-center px-2 font-bold text-lg bg-orange-400 text-white py-3 rounded-lg hover:bg-green-600"
                      >
                        <FaMoneyBillTransfer /> Cash
                      </button>
                      <button
                        onClick={() => {
                          handleWashPayment("CARD");
                        }}
                        className="flex gap-2 flex-row justify-center items-center px-2 font-bold text-lg bg-blue-400 text-white py-3 rounded-lg hover:bg-blue-600"
                      >
                        <FaCreditCard /> Card
                      </button>
                    </>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>

      {showPrintMadal && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
            <PrintSalesReceipt
              services={totals}
              salesData={salesReceipt}
              onClose={() => setShowrintModal(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default POS;

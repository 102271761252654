import React, { useState, useRef, useEffect } from 'react';
import { FaUser, FaSignOutAlt, FaLock, FaChevronDown, FaCodeBranch } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import BranchSelector from './Branch';
import { editAdmin } from '../../api/adminApi';
import CreateAdminModal from '../Modals/Admin/CreateAdminModal';
import { ToastContainer, toast,Bounce } from 'react-toastify';

const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { adminData, logout,setAdminData } = useAuth();
  const navigate = useNavigate();

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleEditProfile = () => {
    setIsModalOpen(true);
    setIsOpen(false);
  };
  const handleEditAdmin = async (updatedAdminData) => {
    try {
      if (updatedAdminData.admin_id) {
    await editAdmin(updatedAdminData);
      // setAdminData(admin);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      logout();
    }
  };

  
  const handleBranchChange = (branch) => {
    // Handle branch change
    console.log('Selected branch:', branch);
    // Refresh data or update UI as needed
  };
 

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>                                                               
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center 
        w-10 h-10 rounded-full 
        hover:bg-gray-100 
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <FaUser className="text-orange-600 w-5 h-5" />
        <FaChevronDown className="ml-1 w-3 h-3 text-gray-500" />
      </button>

      {isOpen && (
        <div 
          className="absolute right-0 mt-2 w-56 
          origin-top-right bg-white 
          divide-y divide-gray-100 
          rounded-lg shadow-lg 
          ring-1 ring-black ring-opacity-5 
          focus:outline-none z-50"
        >
          <div className="px-4 py-3">
            <p className="text-sm font-medium text-gray-900 truncate">
              {adminData?.username}
            </p>
            <p className="text-xs text-gray-500 truncate">
              {adminData?.email}
            </p>
          </div>

          <div className="py-1">
          <BranchSelector onBranchChange={handleBranchChange} />
            <button
              onClick={handleEditProfile}
              className="flex items-center w-full px-4 py-2 
              text-sm text-gray-700 
              hover:bg-gray-100 
              focus:outline-none"
            >
              <FaUser className="mr-3 w-4 h-4 text-gray-500" />
              Edit Profile
            </button>
            {/* <button
              onClick={handleChangePassword}
              className="flex items-center w-full px-4 py-2 
              text-sm text-gray-700 
              hover:bg-gray-100 
              focus:outline-none"
            >
              <FaLock className="mr-3 w-4 h-4 text-gray-500" />
              Change Password
            </button> */}
          </div>

          <div className="py-1">
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 
              text-sm text-red-600 
              hover:bg-red-50 
              focus:outline-none"
            >
              <FaSignOutAlt className="mr-3 w-4 h-4 text-red-500" />
              Logout
            </button>
          </div>
        </div>
      )}

{isModalOpen && (
        <CreateAdminModal
          onClose={handleCloseModal}
          onSubmit={handleEditAdmin}
          adminData={adminData}
          editMyprofile={true}
        />
      )}
    
    </div>
  );
};

export default Profile;
import { FaCheckCircle, FaCreditCard } from "react-icons/fa";
import { FaCircleXmark, FaMoneyBillTransfer } from "react-icons/fa6";

const CurrentSubscription = ({
  subscription,
  onRenew,
  onPay,
  checkSubscriptionStatus,
}) => {
  const isExpired = checkSubscriptionStatus(subscription.end_date).isExpired;
  const paymentStatus = subscription.payment_status;
  const daysRemaining = checkSubscriptionStatus(
    subscription.end_date
  ).daysRemaining;
  const dif =
    new Date(subscription.end_date) - new Date(subscription.start_date);
  const duration = Math.ceil(dif / (1000 * 60 * 60 * 24));

  return (
    <div className="flex flex-col justify-between w-full bg-white rounded-lg shadow-md p-4 mb-2">
      <div className="flex flex-row justify-between items-center">
        <span>Current Subscription Status</span>
        <span
          className={`font-semibold text-2xl ${
            isExpired ? "text-red-600" : "text-green-600"
          }`}
        >
          {isExpired ? <FaCircleXmark /> : <FaCheckCircle />}
        </span>
      </div>
      <div>
        Duration:{daysRemaining} / {duration}
      </div>
      <div>Expiry: {subscription.end_date}</div>
      <div>Payment Status: {paymentStatus}</div>
      {isExpired ? (
        <div className="flex justify-around flex-wrap space-4 gap-2 mt-2">
        <>
          <button
            onClick={()=>onRenew("CASH")}
            className="flex gap-2 flex-row justify-center items-center px-2 font-bold text-lg bg-red-800 text-white py-3 rounded-lg hover:bg-green-600"
          >
            <FaMoneyBillTransfer /> Renew CASH {subscription.subscription_cost} QAR
          </button>
          <button
            onClick={()=>onRenew("CARD")}
            className="flex gap-2 flex-row justify-center items-center px-2 font-bold text-lg bg-gray-800 text-white py-3 rounded-lg hover:bg-blue-600"
          >
            <FaCreditCard /> Renew CARD {subscription.subscription_cost} QAR
          </button>
        </>
        </div>
        
      ) : paymentStatus === "Pending" ? (
        <div className="flex justify-around flex-wrap space-4 gap-2 mt-2">
          <>
            <button
              onClick={()=>onPay("CASH")}
              className="flex gap-2 flex-row justify-center items-center px-2 font-bold text-lg bg-orange-400 text-white py-3 rounded-lg hover:bg-green-600"
            >
              <FaMoneyBillTransfer /> Pay CASH {subscription.subscription_cost} QAR
            </button>
            <button
              onClick={()=>onPay("CARD")}
              className="flex gap-2 flex-row justify-center items-center px-2 font-bold text-lg bg-blue-400 text-white py-3 rounded-lg hover:bg-blue-600"
            >
              <FaCreditCard /> Pay CARD {subscription.subscription_cost} QAR
            </button>
          </>
        </div>
      ) : null}
    </div>
  );
};
export default CurrentSubscription;

import React from 'react';

const Card = ({ title, value, styles }) => {
  return (
    <div className={`flex flex-col justify-center items-center shadow-lg rounded-lg p-2 ${styles}`}>
      <h3 className="text-xs font-semibold">{title}</h3>
      <p className="text-xl font-bold text-blue-600">{value}</p>
    </div>
  );
};

export default Card;

// src/api/SlotApi.js
import config from '../config';

export const fetchSlots = async (filters = {}) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${config.apiUrl}/slots/search/param=?&${query}`);

  if (!response.ok) throw new Error('Failed to fetch Slots');
  return response.json();
};
export const createSlot= async (SlotData) => {
  const response = await fetch(`${config.apiUrl}/slots/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(SlotData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteSlot= async (SlotData) => {
  const response = await fetch(`${config.apiUrl}/slots/${SlotData.slot_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(SlotData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editSlot= async (SlotData) => {
  const response = await fetch(`${config.apiUrl}/slots/${SlotData.slot_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(SlotData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};
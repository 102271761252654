// src/api/adminApi.js
import config from '../config';

export const uploadFile = async (formData) => {
    const response = await fetch(`${config.apiUrl}/banner/upload`, {
      method: "POST",
      body: formData,
    });
  
    if (!response.ok) throw new Error("Failed to upload file");
    return response.json();
  };
import React, { useEffect, useState } from 'react';
import carData from "./../carData.json";
const CarDetails = ({ 
  plateNumber, 
  color, 
  model, 
  type, 
  registration,
}) => {

  const [brandLogo, setBrandLogo] = useState("");

  useEffect(() => {
    const filteredCar = carData.find((car) =>
      car.name.toLowerCase() === model.toLowerCase()
    );
    if (filteredCar) {
      setBrandLogo(filteredCar.image.localThumb); // Assign the logo URL
    } else {
      setBrandLogo(""); // Fallback if no match
    }
  }, [model]);
  return (
    <div className="grid grid-cols-2 md:grid-cols-5 gap-4 p-4 bg-white rounded-lg shadow-md">
      {/* Plate Number */}
      <div className="col-span-2 md:col-span-1 flex flex-col items-center justify-center">
        <div className="text-xl font-bold bg-gray-200 px-4 py-2 rounded-lg">
          {plateNumber}
        </div>
        <small className="text-gray-500 mt-1">Plate Number</small>
      </div>

      {/* Color Block */}
      <div className="flex flex-col items-center justify-center">
        <div 
          className="w-16 h-16 rounded-full border-4 border-gray-300" 
          style={{ backgroundColor: color }}
        />
        <small className="text-gray-500 mt-1">Color</small>
      </div>

      {/* Brand Logo */}
      <div className="flex flex-col items-center justify-center">
        {brandLogo ? (
          <img
            src={brandLogo}
            alt={model}
            className="w-16 h-16 object-contain"
          />
        ) : (
          <div className="w-16 h-16 bg-gray-300 rounded-lg flex items-center justify-center">
            <span className="text-gray-500 text-sm">No Logo</span>
          </div>
        )}
        <small className="text-gray-500 mt-1">{model}</small>
      </div>

      {/* Car Type */}
      <div className="flex flex-col items-center justify-center">
        <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full">
          {type}
        </div>
        <small className="text-gray-500 mt-1">Vehicle Type</small>
      </div>

      {/* Registration */}
      <div className="col-span-2 md:col-span-1 flex flex-col items-center justify-center">
        <div className="bg-green-100 text-green-800 px-3 py-1 rounded-full">
          {registration}
        </div>
        <small className="text-gray-500 mt-1">Registration</small>
      </div>
    </div>
  );
};

export default CarDetails;
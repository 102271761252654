import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchServiceTypes } from "../../../api/serviceTypeApi";
import { createSubscription, editSubscription } from "../../../api/subscriptionApi";
import { Bounce,  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CreateSubscriptionModal = ({ subscriptionData, onSubmit, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    const fetchAndSetServices = async () => {
      const fetcServiceData = await fetchServiceTypes({ name: "" });
      setServiceTypes(fetcServiceData);
    };

    if (subscriptionData) {
      setValue("name_en", subscriptionData.name_en);
      setValue("name_ar", subscriptionData.name_ar);
      setValue("color", subscriptionData.color);
      setValue("icon", subscriptionData.icon);
      setValue("number_of_cars", subscriptionData.number_of_cars);
      setValue("number_of_washes", subscriptionData.number_of_washes);
      setValue("duration_days", subscriptionData.duration_days);
      setValue("one_year_offer", subscriptionData.one_year_offer);
      setValue("expiry_display_date", subscriptionData.expiry_display_date);
      setValue("description_ar", subscriptionData.description_ar);
      setValue("description_en", subscriptionData.description_en);
      setValue("cost", subscriptionData.cost);
    } else {
      reset(); // Reset form if no subscriptionData
    }
    fetchAndSetServices();
  }, [subscriptionData, setValue, reset]);

  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);  
  const formData = new FormData();
  formData.append("subscriptionData", JSON.stringify({
    subscription_id: subscriptionData?.subscription_id || null,
    name_en: data.name_en,
    name_ar: data.name_ar,
    description_en: data.description_en,
    description_ar: data.description_ar,
    color: data.color,
    number_of_cars: data.number_of_cars,
    number_of_washes: data.number_of_washes,
    duration_days: data.duration_days,
    cost: data.cost,
    one_year_offer: data.one_year_offer,
    expiry_display_date: data.expiry_display_date,
  }));

  // Attach the selected services list
  formData.append("servicesList", JSON.stringify(
    data.service_type_ids.map((id) => ({
      service_type_id: parseInt(id),
    }))
  ));

  // Attach the icon file, if selected
  if (data.icon && data.icon?.[0]) {
    formData.append("icon", data.icon[0]);
  }


    try {
      console.log(subscriptionData?.subscription_id); // is printing the correct id
      if (subscriptionData?.subscription_id) {
  
        await editSubscription(subscriptionData?.subscription_id, formData);
      } else {   
        await createSubscription(formData);
        toast.success("Subscription created successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
       
      }
      onSubmit(); //this is just to refetch from the parent component
    } catch (error) {
      console.error("Failed to create subscription:", error);
      
      toast.error(`Error: ${error.message}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setIsSubmitting(false);
      onSubmit();
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-2 mt-4">
      <h2 className="text-xl font-bold text-center mb-6">
        Create Subscription
      </h2>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {/* English Name */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Name (English)
          </label>
          <input
            type="text"
            {...register("name_en", { required: "English name is required" })}
            className="w-full px-3 py-2 border rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
          {errors.name_en && (
            <p className="text-red-500 text-sm">{errors.name_en.message}</p>
          )}
        </div>

        {/* Arabic Name */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Name (Arabic)
          </label>
          <input
            type="text"
            {...register("name_ar", { required: "Arabic name is required" })}
            className="w-full px-3 py-2 border rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
          {errors.name_ar && (
            <p className="text-red-500 text-sm">{errors.name_ar.message}</p>
          )}
        </div>

        {/* Description (English) */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Description (English)
          </label>
          <textarea
            {...register("description_en")}
            rows="4"
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          ></textarea>
        </div>

        {/* Description (Arabic) */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Description (Arabic)
          </label>
          <textarea
            {...register("description_ar")}
            rows="4"
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          ></textarea>
        </div>

        {/* Icon */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Icon</label>
          <input
            type="file"
            {...register("icon")}
            className="w-full px-3 py-2 border rounded-full"
          />
        </div>

        {/* Color Picker */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Color</label>
          <input
            type="color"
            {...register("color")}
            className="w-20 text-3xl h-20 px-3 py-2 border rounded-full"
          />
        </div>

        {/* Number of Cars */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Number of Cars
          </label>
          <input
            type="number"
            {...register("number_of_cars", { required: "Required" })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.number_of_cars && (
            <p className="text-red-500 text-sm">
              {errors.number_of_cars.message}
            </p>
          )}
        </div>
        {/* Number of Washes */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Number of Washes
          </label>
          <input
            type="number"
            {...register("number_of_washes", { required: "Required" })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.number_of_washes && (
            <p className="text-red-500 text-sm">
              {errors.number_of_washes.message}
            </p>
          )}
        </div>

        {/* Duration Days */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Duration (days)
          </label>
          <input
            type="number"
            {...register("duration_days", { required: "Required" })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.duration_days && (
            <p className="text-red-500 text-sm">
              {errors.duration_days.message}
            </p>
          )}
        </div>

        {/* Cost */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Cost</label>
          <input
            type="number"
            {...register("cost", { required: "Cost is required" })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.cost && (
            <p className="text-red-500 text-sm">{errors.cost.message}</p>
          )}
        </div>
        {/* one_year_offer */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            One Year Offer
          </label>
          <input
            type="number"
            {...register("one_year_offer", {
              required: "One Year Offer is required",
            })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.one_year_offert && (
            <p className="text-red-500 text-sm">
              {errors.one_year_offert.message}
            </p>
          )}
        </div>
        {/* one_year_offer */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Expiry Date
          </label>
          <input
            type="date"
            {...register("expiry_display_date", {
              required: "Expiry Date is required",
            })}
            className="w-full px-3 py-2 border rounded-full"
          />
          {errors.expiry_display_date && (
            <p className="text-red-500 text-sm">
              {errors.expiry_display_date.message}
            </p>
          )}
        </div>

        {/* Services Multi-Select */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Services
          </label>
          <select
            {...register("service_type_ids")}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            multiple
          >
            {serviceTypes &&
              serviceTypes?.map((service) => (
                <option
                  key={service.service_type_id}
                  value={service.service_type_id}
                  selected={
                    subscriptionData?.subscriptionServices.some(
                      (subService) =>
                        subService?.service_type_id === service?.service_type_id
                    )
                  } //check if the service_type_id exists in the object subscriptionData.subscriptionServices
                >
                  {service.name_en}
                </option>
              ))}
          </select>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 bg-gray-800 text-white rounded-full"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`px-4 py-2 rounded-full ${
              isSubmitting ? "bg-gray-300" : "bg-[#FF8C00]"
            } text-white`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Creating..." : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateSubscriptionModal;

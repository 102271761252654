// src/components/CreateAdminModal.js
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { fetchBranches } from '../../../api/branchApi'; // API for creating admin

const CreateAdminModal = ({ onClose, onSubmit, adminData,editMyprofile=false }) => {
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const [branches, setBranches] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // For handling user input


  useEffect(() => {
    const fetchAndSetBranches = async () => {
      const fetchedBranches = await fetchBranches({ name: "" });
      setBranches(fetchedBranches);
    };
    if (adminData) {
      // Fill form with admin data for editing
      setValue("username", adminData.username);
      setValue("email", adminData.email);
      setValue("mobile", adminData.mobile);
      setValue("role_type", adminData.role_type);
      setValue("status", adminData.status);
      setValue("branch_id", adminData.branch_id || "none");

    } else {
      reset(); // Reset form if no adminData
    }
    fetchAndSetBranches();
  }, [adminData, setValue, reset]);

  // Fetch branches when searchTerm changes
  useEffect(() => {
    const fetchBranchData = async () => {
      if (searchTerm) {
        try {
          const data = await fetchBranches({ name: searchTerm }); // Await fetchBranches
          setBranches(data); // Assuming 'data' is the array of branches
        } catch (error) {
          console.log("Error fetching branches:", error);
        }
      } else {
        setBranches([]); // Clear branches if there's no search term
      }
    };

    fetchBranchData(); // Call the async function
  }, [searchTerm]);

  const handleBranchChange = (event) => {
    const newBranchId = event.target.value;
    setValue("branch_id", newBranchId); // Update branch in form state
  };
  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };
  const handleFormSubmit = (data) => {
    const processedData = {
      ...data, admin_id: adminData?.admin_id,
      branch_id: data.branch_id === "none" ? null : data.branch_id
   
  };
    onSubmit(processedData);
    onClose();
  };
  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">{adminData ? "Edit Admin" : "Create New Admin"}</h2>

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <input
            {...register('username', { required: true })}
            placeholder="Username"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register('mobile', { required: true })}
            placeholder="Mobile"
            type="mobile"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register('email', { required: true })}

            placeholder="Email"
            type="email"
            className="w-full p-2 border rounded-full mb-3"
          />
       {!editMyprofile && (<> <select
            {...register('role_type', { required: true })}
            className="w-full p-2 border rounded-full mb-3"
          >
            <option value="Super Admin">Super Admin</option>
            <option value="Supervisor">Supervisor</option>
            <option value="Cashier">Cashier</option>
          </select>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchInputChange}
            placeholder="Search for a branch"
            className="border p-2 w-full rounded-full mb-3"
          />
          <select
            name="branch_id"
            {...register('branch_id')}
            value={watch("branch_id") || "none"}
            onChange={handleBranchChange}
            className="border p-2 w-full rounded-full mb-3"
          >
            <option value="none">No Branch</option>
            {branches.map((branch) => (
              <option key={branch.branch_id} value={branch.branch_id}>
                {branch.name}
              </option>
            ))}
          </select></>)}

          <input
            {...register('password', { required: true })}
            placeholder="Password"
            type="password"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register('status', { required: true })}
            placeholder="status"
            type="hidden"
            className="w-full p-2 border rounded-full mb-3" value={"Active"}
          />
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="mr-2 p-2 bg-gray-800 text-white rounded-full">
              Cancel
            </button>
            <button type="submit" className="p-2 bg-[#FF8C00] text-white rounded-full">
              {adminData ? "Save Changes" : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAdminModal;

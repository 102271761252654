// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import ClientSummaryChart from '../components/Charts/ClientSummaryChart';
import TodaySummery from '../components/POS/TodaySummery';
import MySalesData from '../components/Tables/MySalesData';

const Reports = () => {

  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Check if the current month is December (11)
  const nextMonth = today.getMonth() === 11
    ? new Date(today.getFullYear() + 1, 0, 0)  // If it's December, set endOfMonth to January 0th of next year (which is the last day of December)
    : new Date(today.getFullYear(), today.getMonth() + 1, 0);  // Otherwise, set endOfMonth to the last day of the current month

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Pads month with leading zero
    const day = String(date.getDate()).padStart(2, '0'); // Pads day with leading zero
    return `${year}-${month}-${day}`;
  }

  const [startDate, setStartDate] = useState(formatDate(startOfMonth)); // First day of the current month
  const [endDate, setEndDate] = useState(formatDate(nextMonth)); // Last day of the current month or the end of December

  const [refreshKey, setRefreshKey] = useState(0);

  const handleChangeStart = (e) => {
    setRefreshKey((prev) => prev + 1); setStartDate(e.target.value);

  }
  const handleChangeEnd = (e) => {
    setRefreshKey((prev) => prev + 1); setEndDate(e.target.value);
  }


  return (
    <div className="max-w-full w-full flex-wrap sm:flex-wrap p-4">
      <div className="flex gap-2 flex-wrap sm:flex-nowrap shadow-lg mb-2 p-3">
        <div className="w-full sm:w-auto">
          <label className="block mb-2 text-blue-600 text-sm font-semibold">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={handleChangeStart}
            className="w-full border p-2 rounded"
          />
        </div>
        <div className="w-full sm:w-auto">
          <label className="block mb-2 text-blue-600 text-sm font-semibold">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={handleChangeEnd}
            className="w-full border p-2 rounded"
          />
        </div>
      </div>
      <div className="w-full ">  <TodaySummery refreshKey={refreshKey} startDate={startDate} endDate={endDate} /></div>

      <ClientSummaryChart startDate={startDate} endDate={endDate} />
      <div className=" flex justify-center ">
        <MySalesData refreshKey={refreshKey} startDate={startDate} endDate={endDate} />
      </div>
    </div>

  );
};

export default Reports;

import config from "../config";

// Get all sales records
export const getAllSales = async (params) => {
   // Serialize the query parameters
   const queryString = new URLSearchParams(params).toString();
   console.log(queryString)
     const response = await fetch(`${config.apiUrl}/sales/filter/param=?&${queryString}`, {
       method: "GET",
     });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(JSON.stringify(errorResponse));
  }
  return response.json();
};

// Get a specific sales record by ID
export const getSalesById = async (salesId) => {
  const response = await fetch(`${config.apiUrl}/sales/${salesId}`, {
    method: "GET",
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(JSON.stringify(errorResponse));
  }

  return response.json();
};

// Create a new sales record
export const createSales = async (salesData) => {
  const response = await fetch(`${config.apiUrl}/sales`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(salesData),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(JSON.stringify(errorResponse));
  }

  return response.json();
};

// Update an existing sales record by ID
export const updateSales = async (salesId, salesData) => {
  const response = await fetch(`${config.apiUrl}/sales/${salesId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(salesData),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(JSON.stringify(errorResponse));
  }

  return response.json();
};

// Delete a sales record by ID
export const deleteSales = async (salesId) => {
  const response = await fetch(`${config.apiUrl}/sales/${salesId}`, {
    method: "DELETE",
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(JSON.stringify(errorResponse));
  }

  return response.json();
};

export const getTodaySummary = async (params) => {
  // Serialize the query parameters
  const queryString = new URLSearchParams(params).toString();
console.log(queryString)
  const response = await fetch(`${config.apiUrl}/sales/todaysummary/param=?&${queryString}`, {
    method: "GET",
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(JSON.stringify(errorResponse));
  }

  return response.json();
};
// src/pages/Slots.js
import React, { useState, useEffect } from 'react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchSlots, deleteSlot, editSlot, createSlot } from '../api/slotsApi'; // API call for fetching Slot
import SlotTable from './Tables/SlotTable';
import CreatSlotModal from './Modals/Slots/CreatSlotModal';


const Slots = ({ branchData, onCloseSlot }) => {
    const [Slot, setSlot] = useState([]);
    const [selectSlot, setselectSlot] = useState(null);
  
    useEffect(() => {
      console.log("SLOT BRANCH DATA:", branchData);
      async function getSlot() {
        const data = await fetchSlots({ branch_id: branchData.branch_id}); // Fetch slot list
        setSlot(() => data);
      }
      getSlot();
    }, [branchData]);
  
    const handleeditSlot = async (updatedserviceData) => {
      try {
        console.log(updatedserviceData)
        if (updatedserviceData.slot_id) {
          await editSlot(updatedserviceData);
        } else {
          await createSlot(updatedserviceData);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } finally {
        const data = await fetchSlots({ branch_id: branchData.branch_id});// Fetch slot list
        setSlot(data);
      }
    };
  
    const handledeleteSlot = async (slot) => {
      try {
        await deleteSlot(slot);
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } finally {
        const data = await fetchSlots({ branch_id: branchData.branch_id}); // Fetch slot list
        setSlot(data);
      }
    };
  
    const handleEditClick = (slot) => {
      setselectSlot(slot); // Pass the slot data to modal for editing
    };
  
    const onClose = () => {
      onCloseSlot(); // Close slot modal
    };
  
    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
          <div className="flex justify-between items-center mb-4">
         
            <CreatSlotModal
              onSubmit={handleeditSlot}
              slotData={selectSlot}
              branchData={branchData}
            />
          </div>
          <SlotTable Slots={Slot} onEdit={handleEditClick} onDelete={handledeleteSlot} />
          <button
            type="button"
            onClick={onClose}
            className="mr-2 p-2 bg-gray-200 rounded-md"
          >
            Cancel
          </button>
         
        </div>
      </div>
    );
  };
  

export default Slots;

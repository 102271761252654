import React, { useEffect, useState } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateCouponForm from "../components/Modals/Coupons/createCouponForm";
import CouponCardList from "../components/Tables/CouponCardList";
import { deleteCoupon, fetchCoupons } from "../api/couponApi";

const ManageCoupons = () => {
  const [coupons, setCoupons] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(false);
  useEffect(() => {
    async function getsubscriptions() {
      const data = await fetchCoupons({}); // Fetch subscription list
      console.log(data);
      setCoupons(data);
    }
    getsubscriptions();
  }, []);

  const handleAddCoupon = async (newCoupon) => {
    const data = await fetchCoupons({}); // Fetch subscription list
    setCoupons(data);
    setShowForm(false);
  };

  const handleDeletesubscription = async (couponData) => {
    try {
      const msg = await deleteCoupon(couponData); // Fetch subscription list
      const data = await fetchCoupons({}); // Fetch subscription list

      setCoupons(data);
      setShowForm(false);
      toast.success(msg.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleEditClick = (coupon) => {
    console.log(coupon);
    setSelectedCoupon(coupon); // Pass the subscription data to modal for editing
    setShowForm(true);
  };
  const handleShowForm = () => {
    setShowForm(true);
  };

  return (
    <div className="sm:p-2 lg:p-10 md:p-4">
      <div className="flex items-center pb-3 hover:bg-gray-700 font-semibold text-lg text-orange-500">
        <svg className="flex-shrink-0 w-5 h-5" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 6C2.69063 6 0 8.69063 0 12V18C0 18.825 0.69375 19.4719 1.47187 19.7438C3.23438 20.3531 4.5 22.0312 4.5 24C4.5 25.9688 3.23438 27.6469 1.47187 28.2562C0.69375 28.5281 0 29.175 0 30V36C0 39.3094 2.69063 42 6 42H48C51.3094 42 54 39.3094 54 36V30C54 29.175 53.3062 28.5281 52.5281 28.2562C50.7656 27.6469 49.5 25.9688 49.5 24C49.5 22.0312 50.7656 20.3531 52.5281 19.7438C53.3062 19.4719 54 18.825 54 18V12C54 8.69063 51.3094 6 48 6H6ZM12 16.5V31.5C12 32.325 12.675 33 13.5 33H40.5C41.325 33 42 32.325 42 31.5V16.5C42 15.675 41.325 15 40.5 15H13.5C12.675 15 12 15.675 12 16.5ZM9 15C9 13.3406 10.3406 12 12 12H42C43.6594 12 45 13.3406 45 15V33C45 34.6594 43.6594 36 42 36H12C10.3406 36 9 34.6594 9 33V15Z" fill="#FF8C00" />
        </svg>

        <span class="ms-3"> Manage Coupons</span>
      </div>
      {(showForm || !coupons || coupons.length === 0) && (
        <CreateCouponForm
          couponData={selectedCoupon}
          onSubmit={(data) => handleAddCoupon(data)}
          onClose={() => { setShowForm(false); setSelectedCoupon(null) }}

        />
      )}
      <CouponCardList
        coupons={coupons}
        onEdit={handleEditClick}
        onDelete={handleDeletesubscription}
        ShowForm={handleShowForm}
      />
      <ToastContainer />
    </div>
  );
};

export default ManageCoupons;

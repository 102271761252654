// src/pages/ManageAdminUsers.js
import React, { useState, useEffect } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchAdmins,
  deleteAdmin,
  editAdmin,
  createAdmin,
} from "../api/adminApi"; // API call for fetching admins
import AdminTable from "../components/Tables/AdminTable";
import CreateAdminModal from "../components/Modals/Admin/CreateAdminModal";
import SearchAndAddComponent from "../components/SearchAddComponent";

const ManageAdminUsers = () => {
  const [admins, setAdmins] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  useEffect(() => {
    async function getAdmins() {
      //fetchAdmins({ branch_id: 1, role_type: 'Supervisor' });
      const data = await fetchAdmins({}); // Fetch admin list
      setAdmins(data);
    }
    getAdmins();
  }, []);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleOpenModal = () => {
    setSelectedAdmin(null); // Clear selected admin to open modal in "Create" mode
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAdmin(null);
  };

  const handleEditAdmin = async (updatedAdminData) => {
    try {
      if (updatedAdminData.admin_id) {
        await editAdmin(updatedAdminData);
      } else {
        await createAdmin(updatedAdminData);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      const data = await fetchAdmins({}); // Fetch admin list}
      setAdmins(data);
    }
  };

  const handleDeleteAdmin = async (admin) => {
    try {
      await deleteAdmin(admin);
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      const data = await fetchAdmins({}); // Fetch admin list}
      setAdmins(data);
    }
  };

  const handleEditClick = (admin) => {
    setSelectedAdmin(admin); // Pass the admin data to modal for editing
    setIsModalOpen(true);
  };

  return (
    <div className="sm:p-2 lg:p-10 md:p-4">
      <div className="flex items-center pb-3 hover:bg-gray-700 font-semibold text-lg text-orange-500">
        <svg
          className="flex-shrink-0 w-5 h-5"
          viewBox="0 0 60 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 22C18 25.1826 19.2643 28.2348 21.5147 30.4853C23.7652 32.7357 26.8174 34 30 34C33.1826 34 36.2348 32.7357 38.4853 30.4853C40.7357 28.2348 42 25.1826 42 22C42 18.8174 40.7357 15.7652 38.4853 13.5147C36.2348 11.2643 33.1826 10 30 10C26.8174 10 23.7652 11.2643 21.5147 13.5147C19.2643 15.7652 18 18.8174 18 22ZM26.8594 40.7688L28.6031 43.675L25.4813 55.2906L22.1063 41.5188C21.9188 40.7594 21.1875 40.2625 20.4281 40.4594C13.8656 42.1 9 48.0437 9 55.1219C9 56.7156 10.2938 58 11.8781 58H24.225C24.225 58 24.225 58 24.2344 58H24.75H35.25H35.7656C35.7656 58 35.7656 58 35.775 58H48.1219C49.7156 58 51 56.7062 51 55.1219C51 48.0437 46.1344 42.1 39.5719 40.4594C38.8125 40.2719 38.0813 40.7688 37.8938 41.5188L34.5188 55.2906L31.3969 43.675L33.1406 40.7688C33.7406 39.7656 33.0188 38.5 31.8563 38.5H30H28.1531C26.9906 38.5 26.2688 39.775 26.8688 40.7688H26.8594Z"
            fill="#FF8C00"
          />
        </svg>
        <span className="ms-3"> Manage Admins</span>
      </div>
       {/* Create a component for this div to handle search and add */}
       <SearchAndAddComponent
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddClick={handleOpenModal}
        placeholderText="Search admins..."
      />
      <AdminTable
        admins={admins}
        searchTerm={searchTerm}
        onEdit={handleEditClick}
        onDelete={handleDeleteAdmin}
      />
      {isModalOpen && (
        <CreateAdminModal
          onClose={handleCloseModal}
          onSubmit={handleEditAdmin}
          adminData={selectedAdmin}
        />
      )}
    
    </div>
  );
};

export default ManageAdminUsers;

// src/pages/WashCosts.js
import React, { useState, useEffect } from 'react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWashCosts, deleteWashCost, editWashCost, createWashCost } from '../api/washCostApi'; // API call for fetching washCost
import { FaPlusSquare } from 'react-icons/fa';
import WashCostTable from './Tables/WashCostTable';
import CreatWashCostModal from './Modals/WashCost/CreateWashCostModal';


const WashCosts = () => {
  const [washCost, setwashCost] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectWashCost, setselectWashCost] = useState(null);

  useEffect(() => {
    async function getwashCost() {
      const data = await fetchWashCosts({}); // Fetch admin list
      setwashCost(()=>data);
    }
    getwashCost();
  }, []);

  const handleOpenModal = () => {
    setselectWashCost(null); // Clear selected admin to open modal in "Create" mode
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setselectWashCost(null);
  };

  const handleeditWashCost = async (updatedserviceData) => {
    try {
      if (updatedserviceData.wash_cost_id) {
        console.log(updatedserviceData)
        await editWashCost(updatedserviceData);

      } else {
        await createWashCost(updatedserviceData);
      }
    } catch (error) {
   
     toast.error(error.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
    }
    finally {
      const data = await fetchWashCosts({}); // Fetch admin list}
      setwashCost(data);
    }
  };

  const handledeleteWashCost = async (admin) => {
    try {
      await deleteWashCost(admin);
     }catch (error) {

     toast.error(error.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
    }
    finally {
      const data = await fetchWashCosts({}); // Fetch admin list}
      setwashCost(data);
    }
  };

  const handleEditClick = (admin) => {
    setselectWashCost(admin); // Pass the admin data to modal for editing
    setIsModalOpen(true);
  };


  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
               <button
          onClick={handleOpenModal}
          className="bg-blue-600 text-white px-4 py-2 rounded-md"
        >
          <FaPlusSquare />
        </button>
      </div>    
      <WashCostTable washCost={washCost}  onEdit={handleEditClick}
        onDelete={handledeleteWashCost} />
      {isModalOpen && <CreatWashCostModal onClose={handleCloseModal} onSubmit={handleeditWashCost}
        washData={selectWashCost}
      />}
      
    </div>
  
  );
};

export default WashCosts;

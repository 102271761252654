// src/api/SubscriptionApi.js
import config from '../config';

export const fetchSubscriptions = async (filters = {}) => {
  const response = await fetch(`${config.apiUrl}/subscription/`);

  if (!response.ok) throw new Error('Failed to fetch Subscriptions');
  return response.json();
};
export const createSubscription = async (SubscriptionData) => {
  const response = await fetch(`${config.apiUrl}/subscription/`, {
    method: 'POST',
    body: SubscriptionData,
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteSubscription = async (subscription_id, formData) => {
  const response = await fetch(`${config.apiUrl}/subscription/${subscription_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: formData,
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editSubscription = async (subscription_id,formData) => {
  const response = await fetch(`${config.apiUrl}/subscription/${subscription_id}`, {
    method: 'PUT',
    body: formData,
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const purchaseSubscription = async (formData) => {
  const response = await fetch(`${config.apiUrl}/clientSub/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};
export const updateClientSubscription = async (formData) => {
  const response = await fetch(`${config.apiUrl}/clientSub/${formData?.client_subscription_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};
export const AddVehicleToSSubscription = async (formData) => {
  const response = await fetch(`${config.apiUrl}/clientSub/vehicle`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};


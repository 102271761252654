// src/api/clientApi.js
import config from '../config';

export const getAllClients = async () => {
  const response = await fetch(`${config.apiUrl}/clients`);

  if (!response.ok) throw new Error('Failed to fetch clients');
  return response.json();
};
export const fetchClients = async (filters = {}) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${config.apiUrl}/clients/search/param=?&${query}`);

  if (!response.ok) throw new Error('Failed to fetch clients');
  return response.json();
};

export const createClient = async (clientData) => {
  const response = await fetch(`${config.apiUrl}/clients/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(clientData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteClient = async (clientData) => {
  const response = await fetch(`${config.apiUrl}/clients/${clientData.client_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(clientData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editClient = async (clientData) => {
  const response = await fetch(`${config.apiUrl}/clients/${clientData.client_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(clientData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const getClientSummaryReport = async (startDate,endDate) => {
  console.log(startDate, endDate)
  const queryParams = new URLSearchParams({ startDate, endDate }).toString(); // Correctly encodes query params
 console.log(queryParams)
  const response = await fetch(`${config.apiUrl}/clients/client-summary-report/param=?&${queryParams}`);

  if (!response.ok) throw new Error('Failed to fetch clients report');
  return response.json();
};
// src/components/Dashboard.js
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import VehicleTypes from '../components/VehicleTypes';
import ServiceTypes from '../components/ServiceTypes';
import WashCosts from '../components/WashCosts';
import PhotoUploader from '../components/PohotoUploader';
import BannerImage from '../components/BannerImage';

const Settings = () => {
  return (
    <div className="p-2">
      <Tabs>
        <TabList>
          <Tab>Vehicle Types</Tab>
          <Tab>Services</Tab>
          <Tab>Wash Cost</Tab>
          <Tab>Banner</Tab>
        </TabList>

        <TabPanel>
          <h2>Vehicle Types</h2>
          <VehicleTypes />
        </TabPanel>
        <TabPanel>
          <h2>Services</h2>
          <ServiceTypes />
        </TabPanel>
        <TabPanel>
          <h2>Wash Cost</h2>
          <WashCosts />
        </TabPanel>
        <TabPanel>

          <BannerImage />
          <h2 className='p-4'>Update Banner</h2>
          <PhotoUploader />

        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Settings;

import DataTable, { createTheme } from "react-data-table-component";
import { getAllSales } from "../../api/salesApi";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { getAllClients } from "../../api/clientApi";

const ClientsTable = ({}) => {
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [data, setData] = useState([]); // Initialize as empty array

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        setError(null);
        const clients = await getAllClients();
        setData(clients); // Ensure data is always an array
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, []);

  const columns = [
    { name: "ID", selector: (row) => row.client_id, sortable: true },
    { name: "Client", selector: (row) => row.username, sortable: true },
    {
      name: "Vehicles",
      selector: (row) => (row?.vehicle ? row.vehicle.length : 0),
      sortable: true,
    },
    {
      name: "Subscriptions",
      selector: (row) =>
        row.clientSubscriptions ? row.clientSubscriptions.length : 0,
      sortable: true,
    },
    {
      name: "Free Wash",
      selector: (row) => (row.free_wash_status ? "yes" : "no"),
      sortable: true,
    },
    {
      name: "Subscription Status",
      selector: (row) => row.subscription_status,
      sortable: true,
    },
    {
      name: "Email Validation",
      selector: (row) => (row.is_email_validated ? "yes" : "no"),
      sortable: true,
    },
    {
      name: "Mobile Validation",
      selector: (row) => (row.is_mobile_validated ? "yes" : "no"),
      sortable: true,
    },
    {
      name: "Profile",
      selector: (row) => (row.is_profile_complete ? "yes" : "no"),
      sortable: true,
    },
    {
      name: "First Subscription",
      selector: (row) =>
        row.clientSubscriptions?.[0]?.subscription?.name || "No Subscription",
      sortable: false, // Usually not sortable due to nested structure
    },
  ];

  const [filterText, setFilterText] = useState("");

  const filteredData = data.filter((item) =>
    item.username.toLowerCase().includes(filterText.toLowerCase())
  );

  const conditionalRowStyles = [
    {
      when: (row) => row.free_wash_status,
      style: {
        backgroundColor: "rgba(163, 195, 15, 0.9)",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  return (
    <div className=" text-black w-full  overflow-x-auto justify-center">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className=" mt-2">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Search Records..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full 
            focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
            dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <div className="absolute inset-y-0 end-0 flex items-center pe-3">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
          <DataTable
            title={" CLIENTS REPORT "}
            data={filteredData} // Pass the dataset here
            columns={columns} // Pass the column definitions here
            pagination
            fixedHeader={true}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      )}
    </div>
  );
};

export default ClientsTable;

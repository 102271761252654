import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';

const SearchAndAddComponent = ({ 
  searchTerm, 
  onSearchChange, 
  onAddClick, 
  placeholderText = "Search...", 
  addButtonIcon = <FaPlusCircle /> 
}) => {
  return (
    <div className="flex justify-start items-center mb-4 space-x-2">
      <div className="relative w-full">
        <input
          type="text"
          placeholder={placeholderText}
          value={searchTerm}
          onChange={onSearchChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full 
            focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
            dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <div className="absolute inset-y-0 end-0 flex items-center pe-3">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
      </div>
    
      <button
        onClick={onAddClick}
        className="text-blue-300 text-2xl px-4 py-2 rounded-md hover:text-blue-500 transition-colors"
      >
        {addButtonIcon}
      </button>
    </div>
  );
};

export default SearchAndAddComponent;
// src/components/Card.js
import React, { useCallback, useEffect, useState } from 'react';
import Card from '../Card';
import { fetchSlots } from '../../api/slotsApi';
import { useAuth } from '../../context/AuthContext';
import { BiSolidErrorCircle } from 'react-icons/bi';

const SlotList = ({onSlotChange}) => {
    const [Slot, setSlot] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const {adminSelectedBranch } = useAuth();

    const toggleSlotSelection = (slot) => {
      setSelectedSlot(slot.slot_id);
      onSlotChange?.(slot.slot_id);
    };

    useEffect(() => {  

        async function getSlot() {
            if(adminSelectedBranch){
          const data = await fetchSlots({ branch_id: adminSelectedBranch?.branch_id}); // Fetch slot list
          setSlot(() => data);}
          else{setSlot([])}
        }
        getSlot();
      }, [adminSelectedBranch]);

      if (!adminSelectedBranch) {
        return (
          <div className="p-4 text-red-600">
         <BiSolidErrorCircle /> PLEASE SELECT YOUR BRANCH
          </div>
        );
      }

  return (
    <div className="p-4">
    <h3 className="text-lg font-semibold mb-4 text-orange-500">
      SLOTS
    </h3>
    <div className="grid grid-cols-3 gap-4">
      {Slot.map((slot) => (
        <div key={slot.slot_id} onClick={() => toggleSlotSelection(slot)} className={`rounded-lg cursor-pointer transition-all duration-200 
            ${
              selectedSlot === slot?.slot_id
                ? "bg-orange-300 border-orange-400"
                : "hover:bg-gray-100"
            }`}>
          <Card
            title={slot.slot_name}
            value={slot.slot_number}
            styles={'bg-transparent'}
          />
        </div>
      ))}
    </div>
  </div>
  );
};

export default SlotList;

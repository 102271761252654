// src/components/TodaySummery.js
import React, { useEffect, useState } from "react";
import Card from "../Card";
import { getTodaySummary } from "../../api/salesApi";
import { useAuth } from "../../context/AuthContext";

const TodaySummery = ({
  refreshKey,
  startDate = null,
  endDate = null,
  branch_id,
  admin_id,
}) => {
  const [summary, setSummary] = useState(null); // State to store the fetched summary
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const { adminSelectedBranch, adminData } = useAuth();

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        // Get the start and end of today
        const today = new Date();

        const todayStart = startDate
          ? startDate
          : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

        const todayEnd = endDate
          ? endDate
          : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        let params = {
          startDate: todayStart,
          endDate: todayEnd,
          admin_id: admin_id ? admin_id : adminData.admin_id,

        };
        console.log(adminSelectedBranch !== undefined, adminSelectedBranch)
        if ((branch_id !== null && branch_id !== undefined) || (adminSelectedBranch !== null && adminSelectedBranch !== undefined && adminSelectedBranch!=='')) {
         params.branch_id = branch_id !== null && branch_id !== undefined ? branch_id : adminSelectedBranch.branch_id; }
        const response = await getTodaySummary(params);
        setSummary(response);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [refreshKey, adminSelectedBranch, adminData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-1">
      <h3 className="text-sm font-semibold mb-2 text-orange-500">
        Today's Summary
      </h3>
      <div className="flex flex-wrap gap-2 justify-between">
        {summary && (
          <>
            <Card
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              title="SALES /QAR"
              value={summary.totalSales}
            />
            <Card
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              title="CASH /QAR"
              value={summary.totalCash}
            />
            <Card
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              title="PAID WASH / QAR"
              value={summary.paidWashTotal}
            />
            <Card
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              title="# Free wash"
              value={summary.freeWashesCount}
            />
            <Card
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              title="SUBSC / QAR"
              value={summary.subscriptionIncome}
            />
            <Card
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              title="RENEWALS / QAR"
              value={summary.renewalIncome}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TodaySummery;

// src/pages/AdminLogin.js
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import config from '../config'; // Import the config file
import { FaLock } from 'react-icons/fa';
import { Bounce, toast, ToastContainer } from 'react-toastify';

const AdminLogin = () => {
  const { authenticate } = useAuth();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();


  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${config.apiUrl}/admins/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify({
          username: data.usernameOrEmail,
          password: data.password,
        }),
      });
  
      // Check if the response status is OK (status code 200)
      if (response.ok) {
        const responseData = await response.json();
        
        if (responseData.isAuthenticated) {
          toast.success(`${responseData?.username} Welcome!`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          }); 
          // Call the authenticate function to set authentication state
          authenticate(responseData.admin);
          navigate('/'); // Redirect to the dashboard or main page
        } else {
          toast.error(`Login failed. Please check your credentials.`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });         
        }
      } else {
        // Handle the case where the response status is not OK
        toast.error(`Login failed. Please try again.`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {

      toast.error(`Login failed. Please try again.`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-3">
      <div className="w-full max-w-md bg-white p-8 rounded-md shadow-lg">
        <h2 className="text-2xl text-[#FF8C00] font-bold mb-6 text-center flex justify-center items-center"><FaLock /> <span className='px-2'>Admin Login</span> </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium">Username / Email / Mobile</label>
            <input
             type="text"
             {...register('usernameOrEmail', { required: true })}
             placeholder="Username, Email, or Mobile"
            className="mt-1 p-2 border rounded-full w-full"
            />
            {errors.username && <p className="text-red-500 text-xs mt-1">{errors.username.message}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium">Password</label>
            <input
              type="password"
              {...register('password', { required: true })}
              className="mt-1 p-2 border rounded-full w-full"
            />
            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
          </div>
          <button type="submit" className="w-full p-2 bg-[#FF8C00] text-white rounded-full font-bold">
            Login
          </button>
        </form>
      </div>
  
    </div>
  );
};

export default AdminLogin;

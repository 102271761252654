// src/components/Dashboard.js
import React from 'react';
import { useAuth } from '../context/AuthContext';

const Dashboard = () => {
  const { adminData, logout } = useAuth();
  return (
    <div className="p-5 mt-5">
      <div className="">
        <h1 className='text-orange-500 font-bold text-center text-xl'> {adminData.username} -  Wlecome To Go Clean Darshboard</h1>
        <div className='rounded-lg bg-slate-300 w-full  mt-4 p-4 font-bold relative'>
          <p> Role : {adminData.role_type}</p>
          <p> Email : {adminData.email}</p>
          <p className='mb-10'> Mobile : {adminData.mobile}</p>
          <button onClick={logout} className="absolute bottom-4 right-4  w-52 p-2 bg-[#FF8C00] text-white rounded-full font-bold">
            logout
          </button>
        </div>
 
      </div>
    </div>
  );
};

export default Dashboard;

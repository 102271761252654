// src/components/CreatWashCostModal.js
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchBranches } from "../../../api/branchApi";
import { fetchServiceTypes } from "../../../api/serviceTypeApi";
import { fetchVehicleTypes } from "../../../api/vehicletypeApi";

const CreatWashCostModal = ({ onClose, onSubmit, washData }) => {
    const { register, handleSubmit, reset, setValue, watch } = useForm();
    const [branches, setBranches] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);

    useEffect(() => {
        const fetchAndSetBranches = async () => {
            const fetchedBranches = await fetchBranches({ name: "" });
            setBranches(fetchedBranches);
        };
        const fetchAndSetServices = async () => {
            const fetcServiceData = await fetchServiceTypes({ name: "" });
            setServiceTypes(fetcServiceData);
        };
        const fetchAndSetVehicles = async () => {
            const fetchedVehicles = await fetchVehicleTypes({ name: "" });
            setVehicleTypes(fetchedVehicles);
        };
        if (washData) {
            // Fill form with washCost data for editing
            setValue("apply_to_all_branches", !!washData.apply_to_all_branches)
            setValue("cost", washData.cost);
            setValue("branch_id", washData.branch_id || null);
            setValue("service_type_id", washData.service_type_id || null);
            setValue("vehicle_type_id", washData.vehicle_type_id || null);
        } else {
            reset(); // Reset form if no washData
        }
        fetchAndSetBranches();
        fetchAndSetServices();
        fetchAndSetVehicles();
    }, [washData, setValue, reset]);

    const handleFormSubmit = (data) => {
        const processedData = {
            ...data,
            branch_id: data.branch_id === "All Branches" ? null : data.branch_id,
            vehicle_type_id: data.vehicle_type_id === "All Vehicle Types" ? null : data.vehicle_type_id,
            service_type_id: data.service_type_id === "All Services" ? null : data.service_type_id,
            wash_cost_id: washData?.wash_cost_id,
        };
        onSubmit(processedData);

        onClose();
    };
    return (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
                <h2 className="text-2xl mb-4">
                    {washData ? "Edit WASHES" : "Create New WASHES"}
                </h2>

                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <input
                        {...register("cost", { required: true })}
                        placeholder="cost"
                        type="number"
                        className="w-full p-2 border rounded-full mb-3"
                    />

                    <select
                        name="branch_id"
                        {...register("branch_id")}
                        value={watch("branch_id") || "All Branches"}
                        className="border p-2 w-full rounded-full mb-3"
                    >
                        <option value="All Branches">All Branches</option>
                        {branches.map((branch) => (
                            <option key={branch.branch_id} value={branch.branch_id}>
                                {branch.name}
                            </option>
                        ))}
                    </select>
                    <select
                        name="vehicle_type_id"
                        {...register("vehicle_type_id")}
                        value={watch("vehicle_type_id") || "All Vehicle Types"}
                        className="border p-2 w-full rounded-full mb-3"
                    >
                        <option value="All Vehicle Types">All Vehicle Types</option>
                        {vehicleTypes.map((vehicle) => (
                            <option key={vehicle.vehicle_type_id} value={vehicle.vehicle_type_id}>
                                {vehicle.name_en}
                            </option>
                        ))}
                    </select>
                    <select
                        name="service_type_id"
                        {...register("service_type_id")}
                        value={watch("service_type_id") || "All Services"}
                        className="border p-2 w-full rounded-full mb-3"
                    >
                        <option value="All Services">All Services</option>
                        {serviceTypes.map((service) => (
                            <option key={service.service_type_id} value={service.service_type_id}>
                                {service.name_en}
                            </option>
                        ))}
                    </select>
                    <div class="flex items-center mb-4">
                        <input
                            id="apply_to_all"
                            type="checkbox"
                            {...register("apply_to_all_branches")}
                            checked={watch("apply_to_all_branches") || false} // Bind checked to watch
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        /> <label htmlFor="apply_to_all" className="ms-2 text-sm font-medium text-gray-400 dark:text-gray-500">Apply To All Braches</label>
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="mr-2 p-2 bg-gray-800 text-white rounded-full"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="p-2 bg-[#FF8C00]  text-white rounded-full"
                        >
                            {washData ? "Save Changes" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreatWashCostModal;

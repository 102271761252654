// src/components/ServiceTypesTable.js
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const ServiceTypesTable = ({ serviceTypes, onEdit, onDelete }) => {

  return (
    <table className="min-w-full bg-white divide-y divide-gray-200 table-fixed w-full">
    <thead className="bg-gray-50">
      <tr>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase hidden lg:table-cell">الاسم</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
    {serviceTypes?.length > 0 ? (
    serviceTypes.map((servicetype) => (
        <tr key={servicetype.service_type_id}>
          <td className="px-4 py-2 whitespace-normal">
            <div className="flex items-center">
              <div>{servicetype.name_en}</div>
            </div>
            {/* Extra info for mobile view */}
            <dl className="block lg:hidden  mt-2 text-gray-500 text-xs">
              <dd>{servicetype.name_ar}</dd>         
            </dl>
          </td>
          {/* name_en */}
          <td className="px-4 py-2 whitespace-nowrap  overflow-hidden text-ellipsis max-w-xs hidden lg:table-cell">
            {servicetype.name_ar}
          </td> 
          <td className="px-4 py-2 whitespace-nowrap flex space-x-2">
          <button className="text-blue-500" onClick={() => onEdit(servicetype)}>
                <FaEdit />
              </button>
              <button className="text-red-500" onClick={() => onDelete(servicetype)}>
                <FaTrash />
              </button>
          </td>
        </tr>
       ))
    ) : (
      <tr>
        <td colSpan="3" className="text-center py-4">
          No service types available.
        </td>
      </tr>
    )}
    </tbody>
  </table>
  );
};

export default ServiceTypesTable;

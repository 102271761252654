import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '300px',
};
const defaultCenter = { lat: 40.7128, lng: -74.0060 };

const CreateBranchModal = ({ onClose, onSubmit, branchData }) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const [map, setMap] = useState(null);
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCKDtkq_YZF3WZsW44MzRNILMqnKNQ8lus",
  });

  const parseGoogleMapUrl = (url) => {
    try {
      const parsed = JSON.parse(url);
      if (parsed && typeof parsed.lat === 'number' && typeof parsed.lng === 'number') {
        return parsed;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (branchData) {
      setValue("name", branchData.name);
      setValue("location", branchData.location);
      setValue("open_hours", branchData.open_hours);
      setValue("close_hours", branchData.close_hours);
      
      const parsedCoordinates = parseGoogleMapUrl(branchData.google_map_url);
      if (parsedCoordinates) {
        setMarkerPosition(parsedCoordinates);
        setValue("google_map_url", branchData.google_map_url);
      }
    } else {
      reset();
      setMarkerPosition(defaultCenter);
    }
  }, [branchData, setValue, reset]);

  const handleFormSubmit = (data) => {
    const payload = {
      ...data,
      ...(branchData?.branch_id && { branch_id: branchData.branch_id }),
      google_map_url: JSON.stringify(markerPosition),
    };
    onSubmit(payload);
    onClose();
  };

  const handleMapClick = (e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setMarkerPosition(newPosition);
    setValue("google_map_url", JSON.stringify(newPosition));
    
    if (map) {
      map.panTo(newPosition);
    }
  };

  const onMapLoad = (map) => {
    setMap(map);
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">{branchData ? "Edit Branch" : "Create New Branch"}</h2>
      
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <input
            {...register('name', { required: true })}
            placeholder="Branch Name"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register('open_hours', {
              required: true,
              pattern: { value: /^\d{2}:\d{2}$/, message: "Time must be in HH:mm format" },
            })}
            placeholder="Opening Hours"
            type="time"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register('close_hours', {
              required: true,
              pattern: { value: /^\d{2}:\d{2}$/, message: "Time must be in HH:mm format" },
            })}
            placeholder="Closing Hours"
            type="time"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register('location', { required: true })}
            placeholder="Address"
            className="w-full p-2 border rounded-full mb-3"
          />

          {isLoaded && (
            <div className="mb-3">
              <label className="block mb-1">Branch Location on Map</label>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={markerPosition}
                onClick={handleMapClick}
                onLoad={onMapLoad}
                options={{
                  streetViewControl: false,
                  mapTypeControl: false,
                }}
              >
                <Marker
                  position={markerPosition}
                  draggable={true}
                  onDragEnd={(e) => handleMapClick(e)}
                />
              </GoogleMap>
            </div>
          )}

          <input
            type="hidden"
            {...register("google_map_url")}
          />
          
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="mr-2 p-2 bg-gray-800 text-white rounded-full">
              Cancel
            </button>
            <button type="submit" className="p-2 bg-[#FF8C00] text-white rounded-full">
              {branchData ? "Save Changes" : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateBranchModal;
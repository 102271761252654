// src/api/adminApi.js
import config from '../config';

export const fetchAdmins = async (filters = {}) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${config.apiUrl}/admins/search/param=?&${query}`);

  if (!response.ok) throw new Error('Failed to fetch admins');
  return response.json();
};

export const createAdmin = async (adminData) => {
  const response = await fetch(`${config.apiUrl}/admins/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(adminData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteAdmin = async (adminData) => {
  const response = await fetch(`${config.apiUrl}/admins/${adminData.admin_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(adminData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editAdmin = async (adminData) => {
  const response = await fetch(`${config.apiUrl}/admins/${adminData.admin_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(adminData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};
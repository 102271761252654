// src/components/CreatSlotModal.js
import React, { useEffect,} from "react";
import { useForm } from "react-hook-form";

const CreatSlotModal = ({onSubmit, slotData,branchData}) => {
    const { register, handleSubmit, reset, setValue, } = useForm();

    useEffect(() => {    
        if (slotData) {
            setValue("slot_name", slotData.slot_name)
            setValue("slot_number", slotData.slot_number);
        } else {
            reset(); // Reset form if no slotData
        }
    }, [slotData, setValue, reset, branchData]);

    const handleFormSubmit = (data) => {
        const processedData = {
            ...data,
            slot_id: slotData?.slot_id ? slotData.slot_id :null ,
            branch_id: branchData?.branch_id,
        };
        console.log(processedData)
        onSubmit(processedData);
        reset();
    };
    
    return (
       
            <div className="w-full max-w-md">
                <h2 className="text-2xl mb-4">
                MANAGE SLOTS FOR {branchData?.name}
                </h2>

                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <input
                        {...register("slot_number", { required: true })}
                        placeholder="slot_number"
                        type="number"
                        className="w-full p-2 border rounded-full mb-3"
                    />
                    <input
                        {...register("slot_name", { required: true })}
                        placeholder="slot_name"
                        className="w-full p-2 border rounded-full mb-3"
                    />

                    <div className="flex justify-end">
                          <button
                            type="submit"
                            className="p-2 bg-[#FF8C00]  text-white rounded-full"
                        >
                            {slotData ? "Save Changes" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
    
    );
};

export default CreatSlotModal;

// src/api/ServiceTypeApi.js
import config from '../config';

export const fetchServiceTypes = async (filters = {}) => {
  const response = await fetch(`${config.apiUrl}/service-types/`);

  if (!response.ok) throw new Error('Failed to fetch ServiceTypes');
  return response.json();
};
export const createServiceType = async (ServiceTypeData) => {
  const response = await fetch(`${config.apiUrl}/service-types/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ServiceTypeData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteServiceType = async (ServiceTypeData) => {
  const response = await fetch(`${config.apiUrl}/service-types/${ServiceTypeData.service_type_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ServiceTypeData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editServiceType = async (ServiceTypeData) => {
  const response = await fetch(`${config.apiUrl}/service-types/${ServiceTypeData.service_type_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ServiceTypeData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};
// src/components/SlotTable.js
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
const SlotTable = ({ Slots, onEdit, onDelete }) => {

  return (
    <table className="min-w-full bg-white divide-y divide-gray-200 table-fixed w-full">
    <thead className="bg-gray-50">
      <tr>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Slot Number</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase max-w-xs hidden lg:table-cell">Name</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {Slots?.length > 0 ?  Slots.map((slot) => (         
        <tr key={slot.slot_id}>
          <td className="px-4 py-2 whitespace-normal">
            <div className="flex items-center">
              <div>{slot?.slot_name}</div>
            </div>
            <dl className="block lg:hidden  mt-2 text-gray-500 text-xs">
              <dd>{slot?.slot_number}</dd>         
            </dl>
          </td>
          <td className="px-4 py-2 whitespace-nowrap  overflow-hidden text-ellipsis max-w-xs hidden lg:table-cell">
          {slot?.slot_number}
          </td> 
                 <td className="px-4 py-2 whitespace-nowrap flex space-x-2">
          <button className="text-blue-500" onClick={() => onEdit(slot)}>
                <FaEdit />
              </button>
              <button className="text-red-500" onClick={() => onDelete(slot)}>
                <FaTrash />
              </button>
          </td>
        </tr>
        ))
       : (
        <tr>
          <td colSpan="3" className="text-center py-4">
            No Slots available.
          </td>
        </tr>
      )}
    </tbody>
  </table>
  );
};

export default SlotTable;

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const CreateClientModal = ({ onClose, onSubmit , mobile }) => {
  
  const { register, handleSubmit, reset, setValue } = useForm();

  useEffect(() => {
    if (mobile) {
      // Populate form for editing
      setValue("mobile", mobile);
    } else {
      reset(); // Reset form for new client
    }
  }, [mobile, setValue, reset]);

  const handleFormSubmit = (data) => {
    const processedData = {
      ...data,
      email: `${data.username.toLowerCase()}@example.com`, // Generate email programmatically
      password: "defaultPassword123", // Assign default password
      date_created: new Date(), // Add creation date
      is_email_validated: false, // Default to false
      is_mobile_validated: false, // Default to false
      is_profile_complete: false, // Default to false
      profile_pic: null, // Default to null
      subscription_status: "Inactive", // Default to "Inactive"
      free_wash_status: true, // Default to false
    };
    onSubmit(processedData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <input
            {...register("username", { required: true })}
            placeholder="Username"
            className="w-full p-2 border rounded-full mb-3"
          />
          <input
            {...register("mobile", { required: true })}
            placeholder="Mobile"
            type="text"
            className="w-full p-2 border rounded-full mb-3"
          />
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 p-2 bg-gray-800 text-white rounded-full"
            >
              Cancel
            </button>
            <button type="submit" className="p-2 bg-[#FF8C00] text-white rounded-full">
              { "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateClientModal;

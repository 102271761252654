import DataTable, { createTheme } from "react-data-table-component";
import { getAllSales } from "../../api/salesApi";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../context/AuthContext";

const MySalesData = ({
  refreshKey,
  startDate = null,
  endDate = null,
  branch_id,
  admin_id,
}) => {
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [data, setData] = useState([]); // Initialize as empty array
  const { adminSelectedBranch, adminData } = useAuth();

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        setError(null);
        const today = new Date();
        const todayStart =
          startDate ||
          `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        const todayEnd =
          endDate ||
          `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        const params = {
          startDate: todayStart,
          endDate: todayEnd,
          admin_id: admin_id || adminData.admin_id,
        };
        console.log(adminSelectedBranch !== undefined, adminSelectedBranch);
        if (
          (branch_id !== null && branch_id !== undefined) ||
          (adminSelectedBranch !== null &&
            adminSelectedBranch !== undefined &&
            adminSelectedBranch !== "")
        ) {
          params.branch_id =
            branch_id !== null && branch_id !== undefined
              ? branch_id
              : adminSelectedBranch.branch_id;
        }

        const sales = await getAllSales(params);
        setData(sales); // Ensure data is always an array
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [refreshKey, adminSelectedBranch, adminData]);

  const columns = [
    { name: "Sales ID", selector: (row) => row.sales_id, sortable: true },
    {
      name: "Client Username",
      selector: (row) => row.client.username,
      sortable: true,
    },
    {
      name: "Vehicle Info",
      selector: (row) =>
        `${row.vehicle?.model || "N/A"} - ${
          row.vehicle?.plate_number || "N/A"
        }`,
      sortable: true,
    },
    {
      name: "Payment Type",
      selector: (row) => row.payment_type,
      sortable: true,
    },
    { name: "Branch Name", selector: (row) => row?.branch?.name, sortable: true },
    { name: "Action", selector: (row) => row.action, sortable: true },
    { name: "Total", selector: (row) => row.total, sortable: true },
  ];
  const summary = useMemo(
    () => ({
      totalRecords: data ? data.length : 0,
      totalSales: data ? data.reduce((sum, item) => sum + item.total, 0) : 0,
      averageSale:
        data && data.length
          ? data.reduce((sum, item) => sum + item.total, 0) / data.length
          : 0,
      freeWashes: data
        ? data.filter((item) => item.payment_type === "FREE WASH").length
        : 0,
    }),
    [data]
  );

  const [filterText, setFilterText] = useState("");

  const filteredData = data.filter(
    (item) =>
      item.sales_id.toString().includes(filterText) ||
      item.client.username.toLowerCase().includes(filterText.toLowerCase()) ||
      (item.branch?.name || "").toLowerCase().includes(filterText.toLowerCase())
  );

  const conditionalRowStyles = [
    {
      when: (row) => row.action === "SUBSCRIBE",
      style: {
        backgroundColor: "rgba(63, 195, 128, 0.9)",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.action === "WASH",
      style: {
        backgroundColor: "rgba(63, 63, 128, 0.7)",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  return (
    <div className="text-black flex  justify-center row ">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className=" mt-2">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <div className="p-2 bg-blue-50 rounded-lg">
              <p className="text-sm text-gray-600">Total Records</p>
              <p className="text-xl font-bold">{summary.totalRecords}</p>
            </div>
            <div className="p-2 bg-green-50 rounded-lg">
              <p className="text-sm text-gray-600">Total Sales</p>
              <p className="text-xl font-bold">
                QAR {summary.totalSales.toFixed(2)}
              </p>
            </div>
            <div className="p-2 bg-yellow-50 rounded-lg">
              <p className="text-sm text-gray-600">Average Sale</p>
              <p className="text-xl font-bold">
                QAR {summary.averageSale.toFixed(2)}
              </p>
            </div>
            <div className="p-2 bg-purple-50 rounded-lg">
              <p className="text-sm text-gray-600">Free Washes</p>
              <p className="text-xl font-bold">{summary.freeWashes}</p>
            </div>
          </div>

          <div className="relative w-full mb-2">
            <input
              type="text"
              placeholder="Search Records..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full 
            focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
            dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <div className="absolute inset-y-0 end-0 flex items-center pe-3">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>

         <div className="data-table overflow-x-auto"> <DataTable
            title={" SALES REPORT "}
            data={filteredData} // Pass the dataset here
            columns={columns} // Pass the column definitions here
            pagination
            fixedHeader={true}
            fixedHeaderScrollHeight={"300px"}
            conditionalRowStyles={conditionalRowStyles}
          /></div>
        </div>
      )}
    </div>
  );
};

export default MySalesData;

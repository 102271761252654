// src/pages/VehicleTypes.js
import React, { useState, useEffect } from 'react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchVehicleTypes, deleteVehicleType, editVehicleType, createVehicleType } from '../api/vehicletypeApi'; // API call for fetching vehicletype
import { FaPlusSquare } from 'react-icons/fa';
import VehicleTypesTable from './Tables/VehicleTpyesTable';
import CreateVehicleType from './Modals/VehicleTypes/CreateVehicleType';


const VehicleTypes = () => {
  const [vehicletype, setvehicletype] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectVehicleType, setselectVehicleType] = useState(null);

  useEffect(() => {
    async function getvehicletype() {
      const data = await fetchVehicleTypes({}); // Fetch admin list
      setvehicletype(data);
    }
    getvehicletype();
  }, []);

  const handleOpenModal = () => {
    setselectVehicleType(null); // Clear selected admin to open modal in "Create" mode
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setselectVehicleType(null);
  };

  const handleeditVehicleType = async (updatedvehicleData) => {
    try {
      if (updatedvehicleData.vehicle_type_id) {
        await editVehicleType(updatedvehicleData);

      } else {
        await createVehicleType(updatedvehicleData);
      }
    } catch (error) {
   
     toast.error(error.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
    }
    finally {
      const data = await fetchVehicleTypes({}); // Fetch admin list}
      setvehicletype(data);
    }
  };

  const handledeleteVehicleType = async (admin) => {
    try {
      await deleteVehicleType(admin);
     }catch (error) {

     toast.error(error.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
    }
    finally {
      const data = await fetchVehicleTypes({}); // Fetch admin list}
      setvehicletype(data);
    }
  };

  const handleEditClick = (admin) => {
    setselectVehicleType(admin); // Pass the admin data to modal for editing
    setIsModalOpen(true);
  };


  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
               <button
          onClick={handleOpenModal}
          className="bg-blue-600 text-white px-4 py-2 rounded-md"
        >
          <FaPlusSquare />
        </button>
      </div>
      <VehicleTypesTable vehicleTypes={vehicletype}  onEdit={handleEditClick}
        onDelete={handledeleteVehicleType} />
      {isModalOpen && <CreateVehicleType onClose={handleCloseModal} onSubmit={handleeditVehicleType}
        vehicleData={selectVehicleType}
      />}
      
    </div>
  
  );
};

export default VehicleTypes;

import React, { useState } from "react";
import { uploadFile } from "../api/bannerUpload"; // Adjust the path to where the uploadFile function is defined

const PhotoUploader = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files?.[0];
    const validExtensions = ["image/png", "image/jpeg", "image/gif", "image/svg+xml"];
    const maxSize = 800 * 1024; // 800KB

    if (!file) {
      setError("No file selected.");
      return;
    }

    if (!validExtensions.includes(file.type)) {
      setError("Invalid file type. Only PNG, JPEG, GIF, and SVG are allowed.");
      return;
    }

    if (file.size > maxSize) {
      setError("File size exceeds the 800KB limit.");
      return;
    }

    setError("");
    setFile(file);

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setSelectedImage(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      setError("Please select a valid file before submitting.");
      return;
    }

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await uploadFile(formData);
      alert("File uploaded successfully!");
      console.log("Upload response:", response);
    } catch (error) {
      alert("Failed to upload file.");
      console.error("Upload error:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center w-full">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 16"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
            />
          </svg>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            SVG, PNG, JPG or GIF (MAX. 800x400px)
          </p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleImageChange}
        />
      </label>

      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}

      {selectedImage && (
        <div className="mt-4">
          <p className="text-sm text-gray-500 dark:text-gray-400">Preview:</p>
          <img
            src={selectedImage}
            alt="Preview"
            className="w-auto h-48 mt-2 border rounded-lg"
          />
        </div>
      )}

      <button
        type="submit"
        className={`px-4 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-700 ${
          isUploading ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={isUploading || !file}
      >
        {isUploading ? "Uploading..." : "Upload"}
      </button>
    </form>
  );
};

export default PhotoUploader;

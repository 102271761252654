import React, { useState } from "react";

import { fetchClients, createClient} from "../../api/clientApi"; // API function to fetch client by mobile
import CreateClientModal from "../Modals/Client/CreateClientModal";
import EmptyAdd from "../EmptyAdd";
import { BiRefresh } from "react-icons/bi";

const LookUpClient = ({ onClientSelected, onRestClient }) => {
  const [mobile, setMobile] = useState("");
  const [client, setClient] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearch = async (e) => {
    try {
      setMobile(e.target.value);
      if (e.target.value.length >= 7) {
        const fetchedClient = await fetchClients({ mobile: e.target.value }); // Replace with actual API call
        setClient(fetchedClient || null);
        if (fetchedClient) {
          onClientSelected(fetchedClient); // Notify parent if client is found
        }
      }
    } catch (error) {
      console.error("Error fetching client:", error);
      setClient(null);
    }
  };

  const handleCreateClient = async (newClientData) => {
    try {
      const clientData = await createClient(newClientData);
      setClient(clientData); // Update client data after creation
      setIsModalOpen(false); // Close the modal
      onClientSelected(clientData); // Notify parent with the new client data
    } catch (e) {
      console.log(e);
    }
  };
  const handleRestClient =  () => {
    try {
      setMobile("");
      setClient(null); // Update client data after creation
      setIsModalOpen(false); // Close the modal
      onClientSelected(null); // Notify parent with the new client data
      onRestClient();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="relative flex items-center z-0">
        <input
          value={mobile}
          onChange={handleSearch}
          placeholder="Enter Mobile Number"
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-extrabold text-black-500 rounded-full 
            focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
            dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
        />
       
       {client? (<button onClick={handleRestClient} className="absolute inset-y-0 end-4 flex items-center pe-3 text-4xl text-orange-500" ><BiRefresh /></button>):( <div className="absolute inset-y-0 end-4 flex items-center pe-3">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>) }
      </div>

      {!client && (
        <EmptyAdd
          placeholderText="Client Not Registered"
          onAddClick={() => setIsModalOpen(true)}
        />
      )}
      {isModalOpen && (
        <CreateClientModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCreateClient}
          mobile={mobile}
        />
      )}
    </>
  );
};

export default LookUpClient;

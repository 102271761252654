// src/components/VehicleTypesTable.js
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
const VehicleTypesTable = ({ vehicleTypes, onEdit, onDelete }) => {

  return (
    <table className="min-w-full bg-white divide-y divide-gray-200 table-fixed w-full">
    <thead className="bg-gray-50">
      <tr>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase hidden lg:table-cell">الاسم</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {vehicleTypes?.length > 0 ?  vehicleTypes.map((vehicletype) => (         
        <tr key={vehicletype.vehicle_type_id}>
          <td className="px-4 py-2 whitespace-normal">
            <div className="flex items-center">
              <div>{vehicletype.name_en}</div>
            </div>
            {/* Extra info for mobile view */}
            <dl className="block lg:hidden  mt-2 text-gray-500 text-xs">
              <dd>{vehicletype.name_ar}</dd>         
            </dl>
          </td>
          {/* name_en */}
          <td className="px-4 py-2 whitespace-nowrap  overflow-hidden text-ellipsis max-w-xs hidden lg:table-cell">
            {vehicletype.name_ar}
          </td> 
          <td className="px-4 py-2 whitespace-nowrap flex space-x-2">
          <button className="text-blue-500" onClick={() => onEdit(vehicletype)}>
                <FaEdit />
              </button>
              <button className="text-red-500" onClick={() => onDelete(vehicletype)}>
                <FaTrash />
              </button>
          </td>
        </tr>
        ))
       : (
        <tr>
          <td colSpan="3" className="text-center py-4">
            No Vehicle types available.
          </td>
        </tr>
      )}
    </tbody>
  </table>
  );
};

export default VehicleTypesTable;

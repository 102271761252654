import React from "react";
import { FaTrash } from "react-icons/fa";

const CouponCardView = ({ coupon, onDelete }) => {
  const formattedDate = new Date(coupon.valid_until).toLocaleDateString(
    undefined,
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );
  return (
    <div
      className={`relative border border-dashed border-gray-300 rounded-lg shadow-md p-4 text-center ${
        coupon.discount_type === "amount" ? "bg-blue-300" : "bg-green-300"
      } `}
    >
      <h2 className="text-lg font-bold mb-2">{coupon.name}</h2>
      <p className="text-lg font-extrabold text-gray-500">
        <strong>{coupon.discount_value}</strong>{" "}
        {coupon.discount_type === "amount" ? "QAR" : "%"}
      </p>
      <p className="text-sm text-gray-500 mb-1">
        <strong>Expiry:</strong> {formattedDate}
      </p>
      <p className="text-sm text-gray-500 mb-1">
        <strong>Usage:</strong> {coupon.max_users}
      </p>

      <div className=" absolute top-4 right-4">
        <button
          className=" text-white"
          onClick={(e) => {
            e.stopPropagation(); // Prevent event bubbling
            onDelete(coupon);
          }}
        >
          <FaTrash />
        </button>
      </div>
    </div>
  );
};

export default CouponCardView;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchVehicleTypes } from "../../../api/vehicletypeApi";
import CarList from "../../CarModelList";

const CreateVehicleModal = ({ onClose, onSubmit, clientId, vehicleTypes }) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [vehicletype, setvehicletype] = useState([]);
  useEffect(() => {
    async function getvehicletype() {
      const data = await fetchVehicleTypes({}); // Fetch admin list
      setvehicletype(data);
    }
    getvehicletype();
  }, []);
  useEffect(() => {
    // Reset form when modal is opened
    reset({
      client_id: clientId || "",
      vehicle_type_id: "",
      plate_number: "",
      color: "#000000",
      model: "",
      year_made: "",
    });
  }, [clientId, reset]);

  const handleFormSubmit = (data) => {
    const processedData = {
      ...data,
      client_id: clientId, // Ensure client_id is included
      date_created: new Date(), // Add creation date
    };
    onSubmit(processedData);
    onClose();
  };
  const handleCarModel = (data) => {
    console.log(data)
    setValue("model",data);
  };  


  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Create New Vehicle</h2>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {/* Vehicle Type */}
          <select
            {...register("vehicle_type_id", { required: true })}
            className="w-full p-2 border rounded-full mb-3"
          >
            <option value="">Select Vehicle Type</option>
            {vehicletype.map((type) => (
              <option key={type.vehicle_type_id} value={type.vehicle_type_id}>
                {type.name_en}
              </option>
            ))}
          </select>
          <select
           {...register("registration", { required: true })}
                defaultValue={"PRIVATE"}
               className="w-full p-2 border rounded-full mb-3"
              >
                <option  value="PRIVATE">PRIVATE</option>
                <option value="TAXI">TAXI</option>
                <option value="TRANSPORT">TRANSPORT</option>
                <option value="BIKE">BIKE</option>
                <option value="OTHER">OTHER</option>
              </select>

          {/* Plate Number */}
          <input
            {...register("plate_number", { required: true })}
            placeholder="Plate Number"
            className="w-full p-2 border rounded-full mb-3"
          />

          {/* Color */}
          <input
            {...register("color")}
            placeholder="Color"
            type="color"
            className="p-2 border rounded-full mb-3 h-20 w-20"
          />

          {/* Model */}
          <input
            {...register("model")}
            placeholder="Model"
            className="w-full p-2 border rounded-full mb-3"
            disabled={true}
          />
          
        <CarList onCarSelect={handleCarModel}/>

          {/* Year Made */}
          <input
            {...register("year_made", {
              valueAsNumber: true,
              validate: (value) =>
                !value || (value >= 1900 && value <= new Date().getFullYear()),
            })}
            placeholder="Year Made"
            type="number"
            className="w-full p-2 border rounded-full mb-3"
          />

          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 p-2 bg-gray-800 text-white rounded-full"
            >
              Cancel
            </button>
            <button type="submit" className="p-2 bg-[#FF8C00] text-white rounded-full">
              Create
            </button>
          </div>
        </form>

      </div>
    </div>
  );
};

export default CreateVehicleModal;

import { useState } from "react";
import SubscriptionList from "./SubscriptionList";
import { purchaseSubscription } from "../../api/subscriptionApi";
import { FaX } from "react-icons/fa6";
import { Bounce, toast } from "react-toastify";
import { createNotification } from "../../api/notificationApi";

const SubscriptionManager = ({ clientData, vehicleData, returnCallback }) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [costOption, setCostOption] = useState(null); // 'base' or 'oneYear'
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChooseSubscription = (subscription) => {
    setSelectedSubscription(subscription);
    setCostOption(null); // Reset cost option when a new subscription is selected
  };

  const handleCostOptionSelect = (option) => {
    setCostOption(option);
  };

  const handlePurchase = async () => {
    if (!selectedSubscription || !costOption) {
      toast.error(`Please select a subscription and cost option before purchasing.! `, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });      
      return;
    }
    const cost =
      costOption === "base"
        ? selectedSubscription.cost
        : selectedSubscription.one_year_offer;
        const start_date = new Date();
        const duration_days =
        costOption === "base" ? selectedSubscription.duration_days : 365; // One year for one_year_offer
        const end_date = new Date(start_date);
        end_date.setDate(end_date.getDate() + duration_days);
    const payload = {
      client_id: clientData.client_id,
      vehicle_id: vehicleData.vehicle_id,
      subscription_id: selectedSubscription.subscription_id,
      subscription_name: selectedSubscription?.name_en,
      subscription_cost: cost,
      subscription_features:JSON.stringify({number_of_cars: selectedSubscription.number_of_cars,number_of_washes:selectedSubscription?.number_of_washes,color:selectedSubscription?.color,subscriptionServices:selectedSubscription?.subscriptionServices}),
      start_date:start_date,
      end_date: end_date,
      created_date:start_date,
      payment_status:"Pending"
    };
 
    try {
      setIsProcessing(true);
      await purchaseSubscription(payload); // Call API to purchase subscription
      toast.success(`Subscription purchased successfully! `, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      }); 

      returnCallback(selectedSubscription); // Notify parent with the purchased subscription
      setSelectedSubscription(null); // Reset after successful purchase
      setCostOption(null);
      // try {
      //   const newNotification = {
      //     client_id: clientData.client_id, // Replace with the actual client ID
      //     title: 'Subscribed!',
      //     body: `Your ${selectedSubscription?.name_en} is done, ${cost} QAR payment Pending!`,
      //     type: 'subscribe',
      //   };
      //   const createdNotification = await createNotification(newNotification);
      //   console.log('Created Notification:', createdNotification);
      // } catch (error) {
      //   console.error('Error creating notification:', error.message);
      // }
    } catch (error) {
      toast.success("Error purchasing subscription: " + error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="p-1 bg-white rounded-lg shadow-md">
      <h2 className="text-lg font-bold mb-4">Purchase Subscriptions</h2>
      {!selectedSubscription ? (
        <SubscriptionList ChooseSubsciption={handleChooseSubscription} />
      ) : (
        <div className="flex flex-col items-center gap-4">
        <div className="flex gap-4 p-4 text-white rounded-xl"  style={{ backgroundColor: selectedSubscription.color || "#f0f0f0" }}>  <h3 className="text-md font-semibold">
            Selected Subscription: {selectedSubscription.name_en}
          </h3>
          <button
              className={`p-1  rounded-md top-0 right-0
                  ? "bg-green-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => setSelectedSubscription(null)}
            ><FaX /></button> </div>
          <div className="flex gap-4">
            <button
              className={`px-4 py-2 rounded-md ${
                costOption === "base"
                  ? "bg-green-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => handleCostOptionSelect("base")}
            >
              Base Cost: {selectedSubscription.cost} QAR
            </button>
            <button
              className={`px-4 py-2 rounded-md ${
                costOption === "oneYear"
                  ? "bg-green-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => handleCostOptionSelect("oneYear")}
            >
              One Year Offer: {selectedSubscription.one_year_offer} QAR
            </button>
          </div>
          <button
            className="px-6 py-2 bg-blue-500 text-white rounded-md"
            onClick={handlePurchase}
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Purchase"}
          </button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionManager;

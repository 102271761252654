import React, { useEffect, useState } from "react";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fetchVehicles, createVehicle } from "../../api/vehicleApi"; // API function to fetch vehicle by plateNumber

import EmptyAdd from "../EmptyAdd";
import { BiRefresh } from "react-icons/bi";
import CreateVehicleModal from "../Modals/Vehicle/CreateVehicleModal";

const LookUpVehicle = ({ onVehicleSelected, onResetVehicle, clientData, onRefresh }) => {
  const [plateNumber, setPlateNumber] = useState("");
  const [registration, setRegistration] = useState("PRIVATE");
  const [vehicle, setVehicle] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    try {
      const refetch = (async () => {
        if (plateNumber >= 2) {
          const fetchedVehicle = await fetchVehicles({
            plate_number: plateNumber,
            registration: registration,
          }); // Replace with actual API call
          setVehicle(fetchedVehicle || null);
          if (fetchedVehicle && fetchedVehicle?.length !== 0) {
            onVehicleSelected(fetchedVehicle[0]); // Notify parent if vehicle is found
          } else {
            onResetVehicle();
          }
        }
      });
      refetch();
    } catch (error) {
      console.error("Error fetching vehicle:", error);
      setVehicle(null);
    }
  }, [onRefresh]);
  const handleSearch = async (e) => {
    try {
      setPlateNumber(e.target.value);
      if (e.target.value.length >= 2) {
        const fetchedVehicle = await fetchVehicles({
          plate_number: e.target.value,
          registration: registration,
        }); // Replace with actual API call
        setVehicle(fetchedVehicle || null);
        if (fetchedVehicle && fetchedVehicle?.length !== 0) {
          onVehicleSelected(fetchedVehicle[0]); // Notify parent if vehicle is found
        } else {
          onResetVehicle();
        }
      }
    } catch (error) {
      console.error("Error fetching vehicle:", error);
      setVehicle(null);
    }
  };

  const handleCreateVehicle = async (newVehicleData) => {
    try {
      const vehicleData = await createVehicle(newVehicleData);
      setVehicle(vehicleData); // Update vehicle data after creation
      setIsModalOpen(false); // Close the modal
      onVehicleSelected(vehicleData); // Notify parent with the new vehicle data
    } catch (e) {
      console.log(e);
    }
  };
  const handleResetVehicle = () => {
    try {
      setVehicle(null); // Update vehicle data after creation
      setIsModalOpen(false); // Close the modal
      onVehicleSelected(null); // Notify parent with the new vehicle data
      onResetVehicle();
      setPlateNumber("");
    } catch (e) {
      console.log(e);
    }
  };
  const handleRegTypeChange = (e) => {
    try {
      setRegistration(e.target.value);
      setVehicle(null); // Update vehicle data after creation
      setIsModalOpen(false); // Close the modal
      onVehicleSelected(null); // Notify parent with the new vehicle data
      onResetVehicle();
      setPlateNumber("");
    } catch (e) {
      console.log(e);
    }
  };
  const handleOpenModal = (e) => {
    try {
      if (clientData) {
        setIsModalOpen(true);
      } else {
        toast.warn("Register Client First", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="w-full flex space-x-4">
        <div className="relative flex items-center ">
          <input
            value={plateNumber}
            onChange={handleSearch}
            type="number"
            placeholder="Enter Plate Number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-extrabold text-black-500 rounded-full 
      focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 
      dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
      dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

          {vehicle?.length > 0 ? (
            <button
              onClick={handleResetVehicle}
              className="absolute inset-y-0 end-4 flex items-center pe-3 text-4xl text-orange-500"
            >
              <BiRefresh />
            </button>
          ) : (
            <div className="absolute inset-y-0 end-4 flex items-center pe-3">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="flex items-end">
          <select
            id="underline_select"
            defaultValue={"PRIVATE"}
            onChange={handleRegTypeChange}
            className="block p-3 w-full rounded-xl bg-blue-200 text-sm font-extrabold text-black-500 text-center border-2  border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="PRIVATE">PRIVATE</option>
            <option value="TAXI">TAXI</option>
            <option value="TRANSPORT">TRANSPORT</option>
            <option value="BIKE">BIKE</option>
            <option value="OTHER">OTHER</option>
          </select>
        </div>
      </div>

      {(!vehicle || vehicle.length === 0) && (
        <EmptyAdd
          placeholderText="Vehicle Not Found"
          onAddClick={handleOpenModal}
        />
      )}
      {isModalOpen && (
        <CreateVehicleModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCreateVehicle}
          clientId={clientData.client_id}
        />
      )}
    </>
  );
};

export default LookUpVehicle;

// src/pages/ManageSubscriptions.js
import React, { useEffect, useState } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fetchSubscriptions, deleteSubscription } from "../api/subscriptionApi"; // API for creating subscription

import CreateSubscriptionModal from "../components/Modals/Subscription/CreateSubscriptionModal";
import SubscriptionCardView from "../components/Cards/SubscriptionCardView";
import SearchAndAddComponent from "../components/SearchAddComponent";

const ManageSubscriptions = () => {
  const [subscriptions, setSubscriptiones] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showForm, setShowForm] = useState(false);

  const [selectedsubscription, setSelectedsubscription] = useState(null);
  // Filter coupons based on search query
  const filteredSubscription = subscriptions?.filter((subscription) =>
    subscription?.name_en?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  useEffect(() => {
    async function getsubscriptions() {
      const data = await fetchSubscriptions({}); // Fetch subscription list
      setSubscriptiones(data);
    }
    getsubscriptions();
  }, []);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleCloseModal = () => {
    setSelectedsubscription(null);
    setShowForm(false);
  };

  const handleEdit = async () => {
    try {
      const data = await fetchSubscriptions({});
      setSubscriptiones(data);
      setSelectedsubscription(null);
      setShowForm(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleDeletesubscription = async (subscription) => {
    try {
      await deleteSubscription(subscription?.subscription_id);
      const data = await fetchSubscriptions({}); // Fetch subscription list
      setSubscriptiones(data);
      setSelectedsubscription(null);
      setShowForm(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleEditClick = (subscription) => {
    console.log(subscription);
    setSelectedsubscription(subscription); // Pass the subscription data to modal for editing
    setShowForm(true);
  };
  const handleOpenModal = () => {
    setShowForm(true); // Pass the subscription data to modal for editing
  };

  return (
    <div className="sm:p-2 lg:p-10 md:p-4">

      <div className="flex items-center pb-3 hover:bg-gray-700 font-semibold text-lg text-orange-500">
        <svg
          className="flex-shrink-0 w-5 h-5"
          viewBox="0 0 54 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_34)">
            <path
              d="M24 0H30C31.6594 0 33 1.34062 33 3V9C33 10.6594 31.6594 12 30 12H24C22.3406 12 21 10.6594 21 9V3C21 1.34062 22.3406 0 24 0ZM6 6H18V10.5C18 12.9844 20.0156 15 22.5 15H31.5C33.9844 15 36 12.9844 36 10.5V6H48C51.3094 6 54 8.69063 54 12V42C54 45.3094 51.3094 48 48 48H6C2.69063 48 0 45.3094 0 42V12C0 8.69063 2.69063 6 6 6ZM16.5 40.9969C16.5 41.55 16.95 42 17.5031 42H36.5062C37.0594 42 37.5094 41.55 37.5094 40.9969C37.5094 38.2312 35.2687 36 32.5125 36H21.5156C18.75 36 16.5187 38.2406 16.5187 40.9969H16.5ZM27 33C28.5913 33 30.1174 32.3679 31.2426 31.2426C32.3679 30.1174 33 28.5913 33 27C33 25.4087 32.3679 23.8826 31.2426 22.7574C30.1174 21.6321 28.5913 21 27 21C25.4087 21 23.8826 21.6321 22.7574 22.7574C21.6321 23.8826 21 25.4087 21 27C21 28.5913 21.6321 30.1174 22.7574 31.2426C23.8826 32.3679 25.4087 33 27 33Z"
              fill="#FF8C00"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_34">
              <rect width="54" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span class="ms-3"> Manage Subscription</span>
      </div>
      <SearchAndAddComponent
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddClick={handleOpenModal}
        placeholderText="Search subscriptions..."
      />

      <div  className="w-full bg-orange-400 flex flex-wrap gap-2 shadow-md p-2 rounded-md justify-center items-center">
        {filteredSubscription?.map((subscriptionData, index) => (
          <button
            key={subscriptionData.subscription_id}
            type="button"
            onClick={() => handleEditClick(subscriptionData)}
          >
            <SubscriptionCardView
              subscription={subscriptionData}
              onDelete={handleDeletesubscription}
              showDelete={true}
            />
          </button>
        ))}
      </div>
    {showForm &&  <CreateSubscriptionModal
        onClose={handleCloseModal}
        onSubmit={handleEdit}
        subscriptionData={selectedsubscription}

      />}

    
    </div>
  );
};

export default ManageSubscriptions;

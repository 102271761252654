// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchBranches, createBranch, editBranch, deleteBranch } from '../api/branchApi'; // API for creating branch
import BranchTable from '../components/Tables/BranchTable';
import CreateBranchModal from '../components/Modals/Branch/CreateBranchModal';
import Slots from '../components/Slots';
import SearchAndAddComponent from '../components/SearchAddComponent';

const Branches = () => {
  const [branches, setBranches] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSlotModalOpen, setIsSlotModalOpen] = useState(false);
  const [selectedbranch, setSelectedbranch] = useState(null);
  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  useEffect(() => {
    async function getbranchs() {
      const data = await fetchBranches({}); // Fetch branch list
      const formattedBranches = data.map(branch => ({
        ...branch,
        open_hours: formatTime(branch.open_hours),
        close_hours: formatTime(branch.close_hours),
      }));

      setBranches(formattedBranches);
    }
    getbranchs();
  }, []);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleOpenModal = () => {
    setSelectedbranch(null); // Clear selected branch to open modal in "Create" mode
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedbranch(null);
  };

  const handleEdit = async (updatedData) => {
    try {
      if (updatedData.branch_id) {
        await editBranch(updatedData);
      } else {
        await createBranch(updatedData);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      const data = await fetchBranches({}); // Fetch branch list
      const formattedBranches = data.map(branch => ({
        ...branch,
        open_hours: formatTime(branch.open_hours),
        close_hours: formatTime(branch.close_hours),
      }));

      setBranches(formattedBranches);
    }
  };

  const handleDeletebranch = async (branch) => {
    try {
      await deleteBranch(branch);
      const data = await fetchBranches({}); // Fetch branch list
      setBranches(data);
    } catch (error) {

      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleEditClick = (branch) => {
    setSelectedbranch(branch); // Pass the branch data to modal for editing
    setIsModalOpen(true);
  };
  const toggleSlotModal = () => {
    setIsSlotModalOpen((prev) => !prev);
  };
  const showSlotModal = (branch) => {
    setSelectedbranch(branch);
    setIsSlotModalOpen((prev) => !prev);
  };




  return (
    <div className="sm:p-2 lg:p-10 md:p-4">
      <div className="flex items-center pb-3 hover:bg-gray-700 font-semibold text-lg text-orange-500">
        <svg
          className="flex-shrink-0 w-5 h-5"
          viewBox="0 0 28 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 13.5C0.5 9.91958 1.92232 6.4858 4.45406 3.95406C6.9858 1.42232 10.4196 0 14 0C17.5804 0 21.0142 1.42232 23.5459 3.95406C26.0777 6.4858 27.5 9.91958 27.5 13.5C27.5 17.0804 26.0777 20.5142 23.5459 23.0459C21.0142 25.5777 17.5804 27 14 27C10.4196 27 6.9858 25.5777 4.45406 23.0459C1.92232 20.5142 0.5 17.0804 0.5 13.5ZM14 7.5C14.825 7.5 15.5 6.825 15.5 6C15.5 5.175 14.825 4.5 14 4.5C9.03125 4.5 5 8.53125 5 13.5C5 14.325 5.675 15 6.5 15C7.325 15 8 14.325 8 13.5C8 10.1906 10.6906 7.5 14 7.5ZM11 45V29.7281C11.975 29.9062 12.9781 30 14 30C15.0219 30 16.025 29.9062 17 29.7281V45C17 46.6594 15.6594 48 14 48C12.3406 48 11 46.6594 11 45Z"
            fill="#FF8C00"
          />
        </svg>

        <span class="ms-3"> Manage Branches</span>
      </div>
      <SearchAndAddComponent
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddClick={handleOpenModal}
        placeholderText="Search branchs..."
      />
      <BranchTable branchs={branches} searchTerm={searchTerm} onEdit={handleEditClick}
        onDelete={handleDeletebranch} onShowSlot={showSlotModal} />
      {isModalOpen && <CreateBranchModal onClose={handleCloseModal} onSubmit={handleEdit}
        branchData={selectedbranch}
      />}
      {isSlotModalOpen && <Slots onCloseSlot={toggleSlotModal}
        branchData={selectedbranch}
      />}
   
    </div>
  );
};

export default Branches;

import React, { useState } from "react";
import CouponCardView from "../Cards/CouponCardView";
import SearchAndAddComponent from "../SearchAddComponent";


const CouponCardList = ({ coupons,onDelete,onEdit, ShowForm }) => {
  const [searchQuery, setSearchQuery] = useState("");
  if (!coupons || coupons.length === 0) {
    return (
      <div className="text-center text-gray-500 p-4">
        No coupons available. Please add some coupons.
      </div>
    );
  }
  // Filter coupons based on search query
  const filteredCoupons = coupons?.filter((coupon) =>
    coupon?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
const handleSearchChange =(data)=>{ setSearchQuery(data)};
  return (

    <div className="pt-4">            
      <SearchAndAddComponent
        searchTerm={searchQuery}
        onSearchChange={(e) =>handleSearchChange(e.target.value)}
        onAddClick={ShowForm}
        placeholderText="Search by code..."
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {filteredCoupons.length > 0 ? (
          filteredCoupons.map((coupon) => (
            <button
            key={coupon.coupon_id}
            type="button"
            onClick={() => onEdit(coupon)}> 
            <CouponCardView              
             coupon={coupon}
             onDelete={onDelete}
            />
             </button>
          ))
        ) : (
          <p className="text-center text-gray-500 col-span-full">
            No coupons found.
          </p>
        )}
      </div>
    </div>
  );
};

export default CouponCardList;

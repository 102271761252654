// src/components/Dashboard.js
import React from 'react';
import Card from '../components/Card'; // Assume you create a Card component
import ClientsTable from '../components/Tables/ClientsTable';

const Clients = () => {
  return (
    <div className="p-5">
      <ClientsTable />
    </div>
  );
};

export default Clients;

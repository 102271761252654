// src/api/WashCostApi.js
import config from '../config';

export const fetchWashCosts = async (filters = {}) => {

  const response =await fetch(`${config.apiUrl}/wash-costs/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(filters)
  });

  if (!response.ok) throw new Error('Failed to fetch WashCosts');
  return response.json();
};
export const createWashCost = async (WashCostData) => {
  const response = await fetch(`${config.apiUrl}/wash-costs/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(WashCostData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteWashCost = async (WashCostData) => {
  const response = await fetch(`${config.apiUrl}/wash-costs/${WashCostData.wash_cost_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(WashCostData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editWashCost = async (WashCostData) => {
  const response = await fetch(`${config.apiUrl}/wash-costs/${WashCostData.wash_cost_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(WashCostData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};
import React, { useEffect, useRef, useState } from "react";


import { getClientSummaryReport } from "../../api/clientApi"; // Ensure this is your API utility
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
  } from 'recharts';

  export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <div className="bg-warning p-3 shadow">
          <span>{label}</span>
          <br />
          {payload.map((ele, index) => (
            <>
              <small key={index} className="text-secondary">
                {ele.name} : {ele.value}
              </small>
              <br />
            </>
          ))}
        </div>
      );
    }
    return null;
  };

const ClientSummaryChart = ({ startDate, endDate }) => {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!startDate || !endDate) return; // Ensure dates are provided
            setLoading(true);
            setError(null);
            try {
                const today=new Date(endDate);
                today.setDate(today.getDate() + 1)
                const newendate= `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
                const response = await getClientSummaryReport(startDate, newendate);
                setChartData(response);
             
            } catch (err) {
                console.error("Error fetching client summary:", err);
                setError("Failed to load data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate]);

   
        if (!chartData) return
        const barColors = {
            total_clients: {
              fill: 'rgba(75, 192, 192, 0.8)',
              stroke: 'rgba(75, 192, 192, 1)'
            },
            total_active_subscription: {
              fill: 'rgba(54, 162, 235, 0.8)',
              stroke: 'rgba(54, 162, 235, 1)'
            },
            total_expired_subscription: {
              fill: 'rgba(255, 99, 132, 0.8)',
              stroke: 'rgba(255, 99, 132, 1)'
            },
            total_inactive_subscription: {
              fill: 'rgba(153, 102, 255, 0.8)',
              stroke: 'rgba(153, 102, 255, 1)'
            },
            total_profile_complete: {
              fill: 'rgba(255, 206, 86, 0.8)',
              stroke: 'rgba(255, 206, 86, 1)'
            }
          };
        
          return (
            <div className="w-full bg-white rounded-lg shadow-lg p-2 my-6">
              <div className="mb-6">
                <h2 className="text-xl text-center font-bold text-orange-600">Client Statistics</h2>
              </div>
              <div className="flex  justify-center ">
                <div className=" data-table h-96 flex items-center justify-center">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={chartData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip 
                        contentStyle={{
                          backgroundColor: '#e2e8f0',
                          border: '1px solid #e2e8f0',
                          borderRadius: '0.375rem',
                          padding: '0.75rem',
                          color:"black"
                        }}
                      />
                      <Legend  />
                      <Bar
                        dataKey="total_clients"
                        name="Total Clients"
                        fill={barColors.total_clients.fill}
                        stroke={barColors.total_clients.stroke}
                        strokeWidth={1}
                      />
                      <Bar
                        dataKey="total_active_subscription"
                        name="Active Subscriptions"
                        fill={barColors.total_active_subscription.fill}
                        stroke={barColors.total_active_subscription.stroke}
                        strokeWidth={1}
                      />
                      <Bar
                        dataKey="total_expired_subscription"
                        name="Expired Subscriptions"
                        fill={barColors.total_expired_subscription.fill}
                        stroke={barColors.total_expired_subscription.stroke}
                        strokeWidth={1}
                      />
                      <Bar
                        dataKey="total_inactive_subscription"
                        name="Inactive Subscriptions"
                        fill={barColors.total_inactive_subscription.fill}
                        stroke={barColors.total_inactive_subscription.stroke}
                        strokeWidth={1}
                      />
                      <Bar
                        dataKey="total_profile_complete"
                        name="Profile Complete"
                        fill={barColors.total_profile_complete.fill}
                        stroke={barColors.total_profile_complete.stroke}
                        strokeWidth={1}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          );
      
};

export default ClientSummaryChart;

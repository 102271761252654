// src/pages/ServiceTypes.js
import React, { useState, useEffect } from 'react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchServiceTypes, deleteServiceType, editServiceType, createServiceType } from '../api/serviceTypeApi'; // API call for fetching serviceType
import { FaPlusSquare } from 'react-icons/fa';
import ServiceTypesTable from './Tables/ServiceTypesTable';
import CreateServiceType from './Modals/ServiceTypes/CreateServiceType';


const ServiceTypes = () => {
  const [serviceType, setserviceType] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectServiceType, setselectServiceType] = useState(null);

  useEffect(() => {
    async function getserviceType() {
      const data = await fetchServiceTypes({}); // Fetch admin list
      setserviceType(()=>data);
    }
    getserviceType();
  }, []);

  const handleOpenModal = () => {
    setselectServiceType(null); // Clear selected admin to open modal in "Create" mode
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setselectServiceType(null);
  };

  const handleeditServiceType = async (updatedserviceData) => {
    try {
      if (updatedserviceData.service_type_id) {
        await editServiceType(updatedserviceData);

      } else {
        await createServiceType(updatedserviceData);
      }
    } catch (error) {
   
     toast.error(error.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
    }
    finally {
      const data = await fetchServiceTypes({}); // Fetch admin list}
      setserviceType(data);
    }
  };

  const handledeleteServiceType = async (admin) => {
    try {
      await deleteServiceType(admin);
     }catch (error) {

     toast.error(error.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
    }
    finally {
      const data = await fetchServiceTypes({}); // Fetch admin list}
      setserviceType(data);
    }
  };

  const handleEditClick = (admin) => {
    setselectServiceType(admin); // Pass the admin data to modal for editing
    setIsModalOpen(true);
  };


  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
               <button
          onClick={handleOpenModal}
          className="bg-blue-600 text-white px-4 py-2 rounded-md"
        >
          <FaPlusSquare />
        </button>
      </div>    
      <ServiceTypesTable serviceTypes={serviceType}  onEdit={handleEditClick}
        onDelete={handledeleteServiceType} />
      {isModalOpen && <CreateServiceType onClose={handleCloseModal} onSubmit={handleeditServiceType}
        serviceData={selectServiceType}
      />}
   
    </div>
  
  );
};

export default ServiceTypes;

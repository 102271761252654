// src/App.js
import React, { useCallback, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header'; // Import Header component
import Dashboard from './components/Dashboard';
import Settings from './pages/Settings';
import Reports from './pages/Reports';
import POS from './pages/POS';
import Branches from './pages/Branches';
import AdminLogin from './pages/AdminLogin';
import { Helmet } from 'react-helmet';
import { useAuth } from './context/AuthContext';
import ManageAdminUsers from './pages/ManageAdminUsers';
import ManageSubscriptions from './pages/ManageSubscriptions';
import ManageCoupons from './pages/ManageCoupons';
import Clients from './pages/Clients';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const { isAuthenticated } = useAuth();
  const [isOpen, setIsOpen] = useState(false); // For mobile view toggle
  const toggleSidebar = useCallback(() => {
    setIsOpen(!isOpen);
  },[isOpen]);
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="GO CLEANS" />
      </Helmet>
      <ToastContainer />

      {isAuthenticated ? (
        <div className="flex flex-col min-h-screen">
          <Header toggleSidebar={toggleSidebar}/> 
          <div className="flex flex-1">
            <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} /> {/* Render sidebar only when authenticated */}
            <div className="flex-1 p-2">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/admins" element={<ManageAdminUsers />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/branches" element={<Branches />} />
                <Route path="/subscriptions" element={<ManageSubscriptions />} />
                <Route path="/coupons" element={<ManageCoupons />} />
                <Route path="/pos" element={<POS />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<Navigate to="/" />} /> {/* Redirect any unknown routes */}
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col min-h-screen">
          <Header /> 
          <div className=" w-full ">
        <Routes>
          <Route path="/login" element={<AdminLogin />} />
          <Route path="*" element={<Navigate to="/login" />} /> {/* Redirect to login if not authenticated */}
        </Routes>
        </div></div>
      )}
    </Router>
  );
};

export default App;

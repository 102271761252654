// src/components/BranchTable.js
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { FaTableCells } from 'react-icons/fa6';
const BranchTable = ({ branchs, searchTerm,onEdit, onDelete, onShowSlot }) => {
  const filteredbranchs = branchs?.filter((branch) =>
    branch.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <table className="min-w-full bg-white divide-y divide-gray-200 table-fixed w-full">
    <thead className="bg-gray-50">
      <tr>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase hidden lg:table-cell">Location</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase hidden lg:table-cell">Open</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase hidden lg:table-cell">Close</th>
        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {filteredbranchs.map((branch) => (
        <tr key={branch.branch_id}>
          {/* name */}
          <td className="px-4 py-2 whitespace-normal">
            <div className="flex items-center">
              <div>{branch.name}</div>
            </div>
            {/* Extra info for mobile view */}
            <dl className="block lg:hidden  mt-2 text-gray-500 text-xs">
              <dd>location: {branch.location}</dd>
              <dd>open: {branch.open_hours}</dd>
              <dd>close: {branch.close_hours}</dd>             
            </dl>
          </td>

          {/* location */}
          <td className="px-4 py-2 whitespace-nowrap  overflow-hidden text-ellipsis max-w-xs hidden lg:table-cell">
            {branch.location}
          </td>

          {/* open close */}
          <td className="px-4 py-2 whitespace-nowrap hidden lg:table-cell" >
            {branch.open_hours}
          </td>
          <td className="px-4 py-2 whitespace-nowrap hidden lg:table-cell" >
            {branch.close_hours}
          </td>       

          {/* Actions */}
          <td className="px-4 py-2 whitespace-nowrap flex space-x-2">
          <button className="text-blue-500" onClick={() => onShowSlot(branch)}>
                <FaTableCells />
              </button>
          <button className="text-blue-500" onClick={() => onEdit(branch)}>
                <FaEdit />
              </button>
              <button className="text-red-500" onClick={() => onDelete(branch)}>
                <FaTrash />
              </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  );
};

export default BranchTable;
